<template>
  <component
    :is="updateComponent"
    :class="classes"
    :style="styles"
    v-html="html"
  ></component>
</template>

<script>
import store from "../../store";

export default {
  name: "Paragraph",
  props: {
    text: {
      type: String,
      default: "Empty paragraph"
    },
    text2: {
      type: String,
      required: false,
      default: ''
    },
    grid: {
      type: Object,
      default: () => {
        return {
          backgroundColor: "#fff"
        }
      }
    },
    action: {
      type: [Object, Array]
    },
    variables: Object,

    conditionalText: {
      type: Boolean,
      required: false,
      default: false,
    },

    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    classes: {
      type: String,
      default: ""
    }
  },
  computed: {
    updateComponent () {
      if (this.classes.includes('heading')) {
        return 'h1'
      } else if (this.classes.includes('subHeading')) {
        return 'h2'
      } else {
        return 'p'
      }
    },
    html () {
      let result = this.$t(this.text);
      if (this.variables) {
        for (const variable in this.variables) {
          let value = this.$store.state[variable];
          result = this.text, [value]
        }
      }
      return result;
    }
  },
  mounted () {
    if (document.querySelector('.modalOpener')) {
      document.querySelectorAll('.modalOpener').forEach(x => {
        x.addEventListener('click', function () {
          store.commit('setModalOptions', {
            type: 'custom',
            message: this.dataset.text,
            isActive: true,
            closeButton: true
          })
        })
      })
    }
  },
  updated () {
    if (document.querySelector('.modalOpener')) {
      document.querySelectorAll('.modalOpener').forEach(x => {
        x.addEventListener('click', function () {
          store.commit('setModalOptions', {
            type: 'custom',
            message: this.dataset.text,
            isActive: true,
            closeButton: true
          })
        })
      })
    }
  },
  methods: {
    // updateComponent: debounce(function (event) {
    //   this.$emit("component-updated", {
    //     text: event.target.innerText
    //   });
    // }, 2000),
    // async triggerActions () {
    //   console.log("CLICK")
    //   if (this.action.length > 0) {
    //     for (let i = 0; i < this.action.length; i++) {
    //       const act = this.action[i];
    //       const fn = act.fn;
    //       const params = act.params;
    //       await this[fn](params, this);
    //     }
    //     return;
    //   }
    // }
  }
};
</script>

<style lang="scss" scoped>
  .orange-100px-heading{
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: 700;
    font-size: 100px;
    line-height: 115px;
    color: #FF840E;
    text-transform: uppercase;
  }
  .overrideMarginBot{
    @media(max-width: 450px){
      margin-bottom: 0px !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
  }
  .addPadding{
    @media(max-width: 450px){
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }
  }
  .paragraphBg{
    @media(max-width: 450px){
      background: rgba(235, 243, 248, 0.7);
    }
  }
  .headingCustom{
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    color: #b75810;
    &-20{
      font-family: 'Gotham Rounded';
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      color: #b75810;
    }
    @media(max-width: 768px){
      font-size: 25px;
      &-20{
        font-family: 'Gotham Rounded';
        font-style: normal;
        font-weight: 700;
        color: #b75810;
        font-size: 20px;
      }
    }
  }
  .gray-s-subheading{
    font-family: 'Gotham Rounded';
    font-style: normal;
    font-weight: 325;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: rgba(62, 62, 64, 1);
  }
</style>
