export default {
  'Upload a receipt': 'Wyślij dowód zakupu',
  'In this step, please upload the proof of purchase of your products.': 'W tym kroku wyślij dowód zakupu swoich produktów.',
  'Drop your photo here or select file.': 'Dodaj zdjęcie tutaj lub wybierz plik.',
  'Only JPG, PNG and PDF files are supported Maximum file size: 10MB': 'Obsługiwane są tylko pliki JPG, PNG, PDF. Maksymalny rozmiar pliku: 10MB',
  'Only JPG and PNG files are supported Maximum file size: 10MB': 'Obsługiwane są tylko pliki JPG, PNG. Maksymalny rozmiar pliku: 10MB',
  'UPLOAD': 'WYŚLIJ',
  'The following information must be visible:': 'Następujące informacje muszą być widoczne:',
  'Folded or scanned receipts or invoices will not be accepted': 'Zagięte lub zeskanowane paragony lub faktury nie są akceptowane',
  'Retailer / Shop': 'Sprzedawca/Sklep',
  'Date and time of purchase': 'Data i godzina zakupu',
  'Campaign product and its price': 'Produkt kampanii i jego cena',
  'Total sum of the receipt / invoice and applied VAT': 'Łączna suma paragonu/faktury i naliczony podatek VAT',
  'Receipt / Invoice ID (not the bank transaction ID)': 'Numer paragonu/faktury (nie numer transakcji bankowej)',
  'Valid': 'Prawidłowy',
  'Hard to read': 'Trudny do odczytania',
  'Folded': 'Złożony',
  'Cut off': 'Odcięty',
  'Back': 'Cofnij',
  'Continue': 'Kontynuuj',
  'Please erase your personal data with the erase tool': 'Proszę usuń swoje dane osobowe za pomocą narzędzia wymazywania',
  'Crop': 'Przytnij',
  'Erase': 'Wymaż',
  'Drag': 'Przeciągnij',
  'Zoom in': 'Powiększ',
  'Zoom out': 'Pomniejsz',
  'Provide bank details': 'Podaj dane bankowe',
  'Please let us know to which account the refund amount should be transferred.': 'Napisz nam, na jakie konto ma zostać przelana kwota zwrotu.',
  'Account holder first name': 'Imię właściciela konta',
  'Account holder last name': 'Nazwisko właściciela konta',
  'IBAN': 'IBAN',
  'BIC': 'BIC',
  'This field is mandatory': 'To pole jest obowiązkowe',
  'Enter the frist name of the account holder': 'Wprowadź imię właściciela konta',
  'Enter the last name of the account holder': 'Wprowadź nazwisko właściciela konta',
  'Enter your IBAN number': 'Wprowadź swój numer IBAN',
  'Enter your BIC': 'Wprowadź swój kod BIC',
  'Please enter a valid IBAN': 'Podaj prawidłowy numer IBAN',
  'Confirm your identity and claim your refund': 'Potwierdź swoją tożsamość i odbierz zwrot pieniędzy.',
  'Verify your identity by entering your phone number here. It is only used once for verification and not for advertising purposes. After the action, your phone number will be deleted by us.': 'Zweryfikuj swoją tożsamość za pomocą numeru telefonu. Jest on używany tylko raz do weryfikacji, a nie do celów reklamowych. Po zakończeniu weryfikacji Twój numer telefonu zostanie przez nas usunięty.',
  'Mobile phone number': 'Numer telefonu komórkowego',
  'We have sent you a code via SMS to the phone number you provided. Please enter this in the field below. If you did not receive a code, please change your phone number and try again': 'Na podany przez Ciebie numer telefonu wysłaliśmy Ci wiadomość SMS z kodem. Wpisz go w polu poniżej. Jeśli nie otrzymałeś kodu, zmień swój numer telefonu i spróbuj ponownie',
  'Verification code': 'Kod weryfikacyjny',
  'Change the mobile number': 'Zmień numer telefonu komórkowego',
  'The SMS code is incorrect': 'Kod SMS jest nieprawidłowy',
  'Thank you!!': 'DZIĘKUJEMY!!',
  'THANK YOU for participating in this campaign.': 'DZIĘKUJEMY za udział w kampanii.',
  'Your receipt / invoice and your data have been successfully sent to us and are being checked at the moment.As soon as we have checked your data, you will receive either a confirmation e-mail or an e-mail asking us if your data was incomplete or your proof of purchase could not be recorded. Please keep your reference number.': 'Twój paragon/faktura oraz Twoje dane zostały do nas pomyślnie przesłane i są w tej chwili sprawdzane. Gdy tylko sprawdzimy Twoje dane, otrzymasz wiadomość e-mail ze statusem weryfikacji. W przypadku niekompletenego zgloszenia wyślemy email z prośbą o uzupełnienie zgłoszenia. Proszę zachować swój numer transakcji.',
  'Reference number': 'Numer referencyjny:',
  'Copy': 'Kopiuj',
  'The verification can take up to 72 hours. We ask for your patience.': 'Weryfikacja może potrwać do 72 godzin. Dziękujemy za cierpliwość.',
  'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.': 'Ten format pliku nie jest obsługiwany. Akceptowane są tylko pliki w formacie .jpeg, .png i .pdf.',
  'This file format is not supported. Only .jpeg and .png files are accepted.': 'Ten format pliku nie jest obsługiwany. Akceptowane są tylko pliki w formacie .jpeg i .png.',
  'This file is too big. Only files up to 10MB can be uploaded.': 'Ten plik jest za duży. Zapisane mogą być tylko pliki o rozmiarze do 10MB.',
  'Your receipt will now be uploaded and processed.': 'Twój dowód zakupu zostanie teraz zapisany i przeprocesowany.',
  'The receipt will be processed automatically. This may take a minute. Please be patient.': 'Dowód zakupu zostanie przeprocesowany automatycznie. To może zająć chwilę. Dziękujemy za cierpliwość.',
  'If you receive an error message, please record a better version of the receipt and upload it again.': 'Jeśli otrzymasz wiadomość o błędzie, prosimy o zapisanie dowodu zakupu w wersji o lepszej jakości or ponowne jego przesłanie.',
  'Please erase your personal data with the eraser tool.': 'Prosimy usuń swoje dane osobowe za pomocą narzędzia wymazywania.',
  'Please frame the receipt / invoice in the gray rectangle.': 'Umieść paragon/fakturę we wnętrzu szarego prostokąta.',
  'First name': 'Imię',
  'Last name': 'Nazwisko',
  'Phone number': 'Numer telefonu',
  'SMS code': 'Kod SMS',
  'IBAN is not valid': 'Numer IBAN jest nieprawidłowy',
  'First name must contain only letters, hyphens and spaces': 'Imię może zawierać tylko litery, myślniki i spacje.',
  'Last name must contain only letters, hyphens and spaces': 'Nazwisko może zawierać tylko litery, myślniki i spacje.',
  'Copied to clipboard': 'Skopiowano do schowka',
  'receipt-image': 'assets/images/svg/receipt-pl.svg',
  'profiling-text': '<strong>Zgoda na transkrypcje dowodu zakupu</strong>: Będziemy używać danych z dowodów zakupu które nam przesyłasz, aby dopasowywać treści którymi dzielimy się z Tobą i dostarczać Ci oferty, które mogą Cię zainteresować.&nbsp;Masz możliwość zmiany swoich preferencji poprzez kliknięcie <a href="https://www.everydayme.pl/profil"><strong>tutaj</strong></a>.',
  'Oops...': 'Ups...',
  'Back to campaign page': 'Powrót do strony kampanii',

  'Specify barcode number': 'Określ numer kodu kreskowego',
  'Please scan or enter manually the barcode from your product': 'Zeskanuj lub wprowadź ręcznie kod kreskowy swojego produktu',
  'SCAN': 'ZESKANUJ',
  'APPLY': 'ZGŁOŚ',
  'STOP': 'STOP',
  'The barcode number is invalid. Try it again.': 'Kod kreskowy jest nieprawidłowy. Spróbuj ponownie.',
  'Unfortunately no valid barcode can be recognized. Please try to enter the barcode in the field above.': 'Niestety kod kreskowy nie może zostać rozpoznany. Spróbuj wpisać poprawny kod kreskowy w powyższe pole.',
  'Please turn on your camera': 'Włącz swój apparat.',
  'Click here and scan the barcode.': 'Kliknij tutaj I zeskanuj kod kreskowy.',
  'Enter barcode number': 'Wpisz numer kodu kreskowego.',
  'Take a photo of your product unpacked next to the piece of paper with the written unique code on it': 'Zrób zdjęcie nierozpakowanego produktu obok kartki z zapisanym unikalnym kodem.',
  'Please write a piece of paper this number': 'Proszę zapisz ten kod na kartce papieru.',
  'Skip': 'Pominąć',
  'You have already added this product.': 'Już dodałeś ten produkt.',
  'You have already added the maximum number of products.': 'Dodałeś już maksymalną liczbę produktów.',
  'SURVEY': 'ANKIETA',
  'Expired Total': 'LINK WYGAŚŁ',

  errors: {
    default: "Ups!!! coś poszło nie tak!",
    customerToken: "Ups!!! Coś coszło nie tak. Zacznij od nowa!",
    invalidCode: "Link, którego próbujesz użyć już wygasł. Wyślij prośbę o nowy link aby zresetować hasło.",
    manyTries: 'Wystąpił błąd, ponieważ potwierdzenie zakupu zostało przesłane zbyt wiele razy.',
    generateCode: 'Kod weryfikacyjny nie mógł być wysłany, ponieważ numer telefonu jest niedopuszczalny. Sprawdź numer telefonu i wprowadź go ponownie.',
    wrongCode: 'Kod SMS jest nieprawidłowy',
    network: 'Ups. Na serwerze wystąpił błąd. Spróbuj ponownie później.',
    invalidReceipt: 'To nie jest paragon, prześlij zdjęcie ważnego paragonu.'
  },
  dictionary: {
    pl: {
      messages: {
        "namesWithHyphensAndSpaces": `{_field_} może zawierać tylko litery, myślniki i spacje.`,
        "ibanName": `{_field_} może zawierać tylko litery, myślniki i spacje.`,
        "iban": "Numer IBAN jest nieprawidłowy",
        "phoneNumber": "Numer telefonu jest nieprawidłowy",
      },
      names: {
        iban: 'IBAN',
        firstname: 'Imię',
        firstName: 'Imię',
        lastname: 'Nazwisko',
        lastName: 'Nazwisko',
        phoneNumber: 'Numer telefonu',
        code: 'Kod SMS'
      }
    }
  },

  'Procter & Gamble is data controller of the personal data you provide. Data related to your bank account number will be processed for the purpose of providing you with money refund. Your phone number will be processed for the purpose of preventing frauds and abuses. You can exercise your data protection rights or change your preferences and subscriptions by clicking here. You can find out more about how we use your data in our Privacy Policy.': `Administratorem Twoich danych osobowych jest <a href='https://www.pg.com/privacy/polish/privacy_statement.shtml#entities' target='_parent'><u>Procter & Gamble</u></a>. Twoje dane osobowe w zakresie danych dotyczących numeru rachunku bankowego będą przetwarzane w celu realizacji zwrotu pieniędzy. Twój numer telefonu będziemy wykorzystywać w celu zapobiegania nadużyciom i oszustwom.
  <br>
  Masz możliwość egzekwowania swoich <a href='https://www.everydayme.pl/data-information' target='_parent'><u>praw do ochrony swoich danych osobowych</u></a> lub zmiany preferencji i subskrypcji poprzez kliknięcie <a href='https://www.pg.com/privacy/polish/privacy_statement.shtml#preferences' target='_parent'><u>tutaj</u></a>.
  <br>
  Dowiedz się wiecej w naszej <a href='https://www.pg.com/privacy/polish/privacy_statement.shtml' target='_parent'><u>Polityce Prywatności</u></a> na temat tego w jaki sposób przetwarzamy Twoje dane osobowe.`,


  // GOogle translated
  'Oops, something went wrong. Please try again.': 'Ups! Coś poszło nie tak. Spróbuj ponownie.',
  'This session has expired': 'Ta sesja wygasła',
  'Unfortunately the link is no longer valid! Please participate again or contact our support team': 'Niestety ten link już nie jest aktywny! Weź udział jeszcze raz albo skontaktuj się z naszym Supportem',
  'The promotion period is over': 'Okres promocji się zakończył',
  'Sign up to stay up to date. Receive special offers and updates.': 'Zapisz się, aby być na bieżąco. Otrzymuj specjalne oferty i aktualności',
  'Please upload your receipt again here': 'Zapisz ponownie swoje potwierdzenie zakupu tutaj',
  'Please upload your product photo here': 'Zapisz ponownie zdjęcie swojego produktu tutaj',
  'Please enter the first name and last name of the account holder exactly as they would be given at the bank.': 'Proszę wpisać imię i nazwisko posiadacza rachunku dokładnie tak, jak zostałyby podane w banku.',
  'Please do not try to enter the same bank details that the first attempt at transfer failed with.': 'Nie próbuj wprowadzać tych samych danych bankowych, które nie powiodły się przy pierwszej próbie przelewu.',
  'Thank you for resubmitting your bank details.<br>So there should now be a successful transfer. If you have any questions related to your bank details, please contact link.': 'Dziękujemy za ponowne przesłanie danych bankowych.<br>Więc teraz powinien nastąpić pomyślny transfer. Jeśli masz jakiekolwiek pytania dotyczące danych bankowych, skontaktuj się z <a href="https://pg-lex.my.salesforce-sites.com/CarehubStandalone?Country=pl&Language=pl&Brand=Everydayme#brandListDiv" target="_blank"><u>https://pg-lex.my.salesforce-sites.com/CarehubStandalone?Country=pl&Language=pl&Brand=Everydayme#brandListDiv</u></a>.'
}
