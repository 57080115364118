<template>
  <div
    class="barcode-scanner__search"
  >
    <b-input-group class="barcode-scanner__search-input">
      <b-form-input
        :value="barcode"
        @click="hideValidation()"
        @input="handleInput($event)"
        :state="inputStatus"
        placeholder="5208467303987"
      >
      </b-form-input>
      <div
        class="validation-error"
        v-if="disabled && error"
      >
        <div class="error_message">
          {{ errorMessage }}
        </div>
      </div>
      <b-form-invalid-feedback id="input-live-feedback">
        {{ $t(texts.invalidBarCode) }}
      </b-form-invalid-feedback>
    </b-input-group>
    <buttonComponent
      :disabled="disabled"
      classes="btn-tertiary"
      @click.native="searchProductByBarcode({barcode, maxProductCount, endpoint})"
      :text="$t(texts.barcodeAdd)"
      :grid="{ columns: 6 }"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import buttonComponent from "@/components/renderers/button-component.vue";
export default {
  name: "SearchModule",
  components: { buttonComponent },
  data() {
    return {
      disabled: false,
      error: false,
      errorMessage: ""
    };
  },
  props: {
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    maxProductCount: {
      type: Number,
      default: 1
    },
    endpoint: {
      type: String,
      default: '/searchBarcodeCentralEurope'
    }
  },
  computed: {
    ...mapState({
      barcode: (state) => state.productCode,
      status: (state) => state.searchStatus
    }),
    inputStatus() {
      let state;
      switch (this.status) {
        case "success":
          state = true;
          break;
        case "not_found":
          state = false;
          break;
        case null:
          state = null;
          break;
        default:
          break;
      }
      return state;
    }
  },
  methods: {
    ...mapActions([
      "uploadFile",
      "findProducts",
      "searchProductByBarcode"
    ]),
    ...mapMutations(["setSearchStatus", "setProductCode"]),
    handleInput(ev) {
      this.setSearchStatus(null);
      this.setProductCode(ev);
    },
    hideValidation() {
      this.setSearchStatus(null);
    },
    resetSelectedProducts() {
      this.$store.commit("resetProducts");
      this.$store.commit("setProductCode", "");
      this.$store.commit("setSearchStatus", null);
    },
    updateBarcode() {
      this.$store.commit("setProducts", [{
        ean: this.barcode,
        name: this.barcode
      }]);
    }
  },
  mounted() {
    this.code = this.barcode;
    this.$eventHub.$on("resetProducts-event", this.resetSelectedProducts);
  }
};
</script>

<style scoped lang="scss">
input[type="file"] {
  position: absolute;
  left: -99999px;
}
.hide {
  display: none;
}
.form-control {
  display: block;
  width: 100%;
  font-size: 14px !important;

  &::placeholder {
    color: #dae0e7;
    font: italic normal 400 14px/25px "Source Sans Pro";
  }
}
label {
  margin-bottom: 0;
}
.was-validated .form-control:invalid,
.form-control.is-invalid,
.form-control:valid,
.form-control.is-valid {
  background-image: none !important;
}
.barcode-scanner__search {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 0 !important;
  &-input {
    input {
      height: 48px;
      color: #200e32;
      z-index: 0 !important;
      background: #FCFCFC;
      border: 2px solid #DFDFF7;
      border-radius: 4px;
      margin-bottom: 20px;
      @media (max-width: 425px) {
        font-size: 10.5px;
      }
    }
  }
}

.error_message {
  display: inline-block;
}

.plus {
  @media (max-width: 450px) {
    display: none;
  }
}

.invalid-feedback,
.validation-error,
.valid-feedback {
  top: 48px !important;
}

</style>
