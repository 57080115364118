<template>
  <div class="wrapper">
    <div class="item">
      <div class="digit">
        1
      </div>
      <div class="inner">
        <div v-html="$t('Please write a piece of paper this number')">
        </div>
        <div class="code">
          {{ uniqueCode }}
          <img
            src="@/assets/images/svg/paper.svg"
            alt="Paper"
          >
        </div>
      </div>
    </div>
    <div class="item">
      <div class="digit">
        2
      </div>
      <div class="inner">
        <div>
          <img
            :src="require(`@/${productPhoto}`)"
            alt="Product Image"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

const braunCmps = [
  // Braun PL Test Campaigns
  '1544',
  '1545',
  '1546',
  '1547',
  '1549',
  '1548',
  '1550',
  '1551',
  // Braun PL Prod Campaigns
  '1725',
  '1724',
  '1726',
  '1727',
  '1729',
  '1728',
  '1730',
  '1731',
  // Braun CZ Test Campaigns
  '1568',
  '1569',
  '1570',
  '1571',
  '1572',
  '1573',
  // Braun CZ Prod Campaigns
  '1738',
  '1739',
  '1740',
  '1741',
  '1742',
  '1743',
  // Braun SK Test Campaigns
  '1562',
  '1563',
  '1564',
  '1565',
  '1566',
  '1567',
  // Braun SK Prod Campaigns
  '1732',
  '1733',
  '1734',
  '1735',
  '1736',
  '1737',
]

const oralbCmps = [
  // OralB PL Test Campaigns
  '1574',
  '1575',
  '1576',
  '1577',
  // OralB PL Prod Campaigns
  '1711',
  '1712',
  '1713',
  '1714',
  // OralB CZ Test Campaigns
  '1578',
  '1579',
  '1580',
  '1581',
  // OralB CZ Prod Campaigns
  '1715',
  '1716',
  '1717',
  '1718',
  // OralB SK Test Campaigns
  '1582',
  '1583',
  '1584',
  '1585',
  // ORALB SK Prod Campaigns
  '1719',
  '1720',
  '1721',
  '1722',
]

export default {
  name: "UniqueCode",
  data () {
    return {
      uniqueCode: 'XXX'
    }
  },
  props: {
    endpoint: {
      type: String,
      default: '/createUniqueCodeCeMb'
    }
  },
  mounted () {
    const formData = {
      campaignId: this.campaignId,
      customerToken: this.customerToken,
      receiptToken: this.receiptToken
    }
    this.sendRequest({ endpoint: this.endpoint, formData }).then(res => {
      this.uniqueCode = res.uniqueCode
    }).catch(err => {
      console.log(err)
    })
  },
  computed: {
    ...mapGetters(['customerToken', 'receiptToken', 'campaignId']),
    productPhoto () {
      if (braunCmps.includes(this.campaignId))
        return 'assets/images/png/unique-code-braun.png'
      else 
        return 'assets/images/png/unique-code-oralb.png'
    }
  },
  methods: {
    ...mapActions(['sendRequest'])
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 20px;
    
    .digit {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      padding: 5px;
      background: #7879F1;
      border: 1.84999px solid #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF;
      font-size: 26px;
    }

    .inner {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }

    .code {
      display: flex;
      justify-content: space-evenly;
      font-size: 40px;
      line-height: 50px;
      font-weight: 700;
    }
    img {
      max-width: 200px;
    }
  }

}
</style>
