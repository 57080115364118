
export default {
  isEditMode: true,
  pageProperties: {
    testing: false,
    "pages": [
      { // Campaign not found
        "pageName": "home",
        isPublic: true,
        pageId: 100,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "classes": "container",
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px",
                    "marginTop": "80px"
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginTop: "50px",
                          marginBottom: "50px"
                        },
                        text: "Campaign not found",
                        classes: 'heading text-center'
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    ],
    "header": {
      "fieldType": "navbar",
      "fieldId": "cbx34t",
      "properties": {
        "grid": {
          "columns": 12
        },
        "favicon": {
          "imageSource": "assets/images/svg/pglogo.svg"
        },
        "meta": [
          { "name": "title", "content": "PG Central Europe" },
          {
            "name": "description",
            "content": ``
          },
          {
            "name": "keywords",
            "content": `PG Central Europe`
          }
        ]
      }
    },
    "footer": {
    },
  },
  componentStatus: {},
  modal: {
    type: 'error',
    message: 'An error occured.',
    isActive: false
  },
  customerToken: '',
  receiptToken: '',
  campaignId: '',
  formData: new FormData()
}
