<template>
  <button
    type="button"
    :class="`btn ${classes}`"
    :style="[styles]"
    :disabled="disabled || loading || dependencies"
    @click="clicked"
  >
    <div v-if="!loading">
      {{ $t(text) }}</div>
    <div
      v-else
      class="spinner-border spinner-border-sm"
      role="status"
    >
      <span class="sr-only"></span>
    </div>
  </button>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "button-component",
  data () {
    return {
      loading: false,
      homepageLinks: {
        'pl': 'https://www.everydayme.pl/',
        'cs': 'https://mujsvet-pg.cz',
        'sk': 'https://mujsvet-pg.cz'
      }
    };
  },
  props: {
    requires: {
      type: Array
    },
    text: {
      required: false,
      type: String,
      default: "Button"
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    action: {
      type: [Object, Array]
    },
    classes: {
      type: String,
      default: ""
    },
    styles: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['hasUnresolvedDependencies']),
    dependencies () {
      return this.hasUnresolvedDependencies(this.requires);
    }
  },
  methods: {
    async clicked () {
      if (!this.action) return;
      if (this.action.anchor) {
        if (this.action.link === '#homepage') {
          window.open(this.homepageLinks[this.$i18n.locale], '_parent')
          return;
        }
        window.open(this.action.link, '_parent')
        // window.location.href = this.action.link
      }
      if (this.action.length > 0) {
        for (let i = 0; i < this.action.length; i++) {
          const act = this.action[i];
          const fn = act.fn;
          const params = act.params;
          await this[fn](params, this);
        }
        return;
      }
      const fn = this.action.fn;
      const params = this.action.params;
      await this[fn](params, this);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/mixins/style/_button.mixin";
@import "@/mixins/style/_color";

.btn {
  @include button();
  &-primary {
    background: linear-gradient(136.6deg, #FF7EDB 4.79%, #E100A1 88.14%);
    border-radius: 8px;
    &:focus,
    &:hover {
      background: linear-gradient(136.6deg, #CA8AB8 4.79%, #A72983 88.14%);
    }
    &:active {
      background: linear-gradient(136.6deg, #FF7EDB 4.79%, #E100A1 88.14%);
    }
    &:disabled {
      background: linear-gradient(136.6deg, #DBD7DA 4.79%, #F5B6E3 88.14%);
    }
  }
  &-secondary {
    background-color: #FFF;
    border: 1px solid #E100A1;
    color: #E100A1;
    &:focus,
    &:hover {
      background-color: #FFF;
      border: 1px solid #CA8AB8;
    }
    &:active {
      background-color: #FFF;
      border: 1px solid #FF7EDB;
    }
    &:disabled {
      background-color: #FFF;
      border: 1px solid #DBD7DA;
    }
  }
  &-tertiary {
    background: #8183F0;
    border-radius: 10px;
    border: none;
    color: #FFF;
    &:focus,
    &:hover {
      background: #8183F0;
      border: 1px solid #FFF;
    }
    &:active {
      background: #8183F0;
      border: 1px solid #8183F0;
    }
    &:disabled {
      background: #8183F0;
      border: 1px solid #DBD7DA;
    }
  }
}
</style>
