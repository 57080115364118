<template>
  <nav
    class="navbar navbar-wrapper navbar-expand-lg"
    ref="navbarWrapper"
    :style="[cssVars, styles]"
  >
    <div class="inner-wrapper container">
      <div class="top">
        <a
          v-if="!!logo"
          class="navbar-brand p-0"
          :href="logo.url || '/'"
          aria-label="Website"
        >
          <img
            :src="require(`@/${logo.imageSource}`)"
            :alt="logo.alt"
          />
        </a>
        <div class=" justify-content-center d-none d-lg-flex d-xl-flex">
          <h1
            v-if="title"
            class="m-0 p-0"
          ></h1>
        </div>
      </div>
      <div class="bottom">
        <button
          v-if="fields.length > 0"
          class="navbar-toggler"
          type="button"
          @click="checkBoxClicked"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="navbar-collapse expandedDiv"
          ref="expandedDiv"
        >
          <ul class="navbar-nav">
            <li
              class="navbarLinks"
              v-for="field in fields"
              :key="field.fieldId"
            >
              <componentRenderer
                :key="field.id"
                :field="field"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import componentRenderer from "@/components/component-renderer.vue";

export default {
  name: "Navbar",
  components: {
    componentRenderer
  },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {
          "background": "#FFF",
          "color": "#000"
        }
      }
    },
    title: {
      type: String
    },
    logo: {
      type: Object,
      default: () => {}
    },
    fields: {
      required: false,
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    cssVars () {
      return {
        '--color': this.styles.color,
        '--bg-color': this.styles.background
      }
    }
  },
  methods: {
    checkBoxClicked () {
      const expandedDiv = this.$refs['expandedDiv'];
      const navbarWrapper = this.$refs['navbarWrapper']
      expandedDiv.style.maxHeight = expandedDiv.style.maxHeight ? null : expandedDiv.scrollHeight + 10 + "px";
      navbarWrapper.style.borderRadius = navbarWrapper.style.borderRadius === '10px' ? '30px' : "10px"
      navbarWrapper.style.height = navbarWrapper.style.height === '150px' ? null : "150px"
    }
  }
};
</script>

<style lang="scss" scoped>
.navbar-wrapper {
  justify-content: space-between;
  @media(max-width: 450px){
    max-width: 100vw !important;
    display: flex;
    align-items: center;
    margin: 0px !important;
  }
}
.inner-wrapper {
  width: 100%;
  height: 100%;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .navbar-brand {
    img {
      max-width: 153px;
      margin: 0 20px;
      @media(max-width: 450px){
        max-width: 90px;
        margin: 0px !important;
      }
    }
  }
  div {
    width: 100%;
    &.top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &.bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .navbar-nav {
        width: 100%;
        justify-content: flex-start;
        column-gap: 20px;
      }
    }
  }
}
.navbar-collapse{
  flex-grow:initial;
}
.navbar-toggler-icon{
  background-image: url('@/assets/images/svg/navbar-toggler.svg') !important;
  width: 24px !important;
  height: 24px !important;
}
.expandedDiv {
  @media (max-width: 991px) {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
    -moz-transition: height 0.4s ease-out;; /* Firefox 4 */
    -webkit-transition: height 0.4s ease-out;;  /* Safari and Chrome */
    -o-transition: height 0.4s ease-out;;  /* Opera */
  }
  @media(max-width: 450px){
    background-color: white;
  }
}
</style>
