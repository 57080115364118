<template>
  <div class="container" ref="questionsRef" :style="cssVars">
    <div class="row flex-column justify-content-center align-items-center">
      <div
        class="col-lg-12 question-wrapper"
        v-for="(question, idx) in questions"
        :key="idx"
      >
        <div class="question">
          <div class="question" v-html="question.text"></div>
          <div class="subQuestion" v-html="question.subText"></div>
          <div class="btn-group w-100" role="group" aria-label="Basic radio toggle button group">
            <input type="radio" v-model="questionModel[idx]" value="yes" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off">
            <label class="btn btn-outline-primary" for="btnradio2">Tak</label>

            <input type="radio" v-model="questionModel[idx]" value="no" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off">
            <label class="btn btn-outline-primary" for="btnradio3">Nie</label>
          </div>
        </div>
        <div v-if="questionModel[0] == 'yes'">
          <Date-Input
            :grid="{ columns: 12 }"
            id="survey-date"
            name="survey-date"
            label="Data urodzenia dziecka"
            placeholder="Data urodzenia dziecka*"
            :label-on-top="true"
            validation="required"
            @component-updated="questionModel[1] = $event.value"
          />
          <small v-if="error" class="my-4" style="color: red;">
            {{ error }}
          </small>
        </div>
        <p class="my-3">
          <i><a href="https://privacypolicy.pg.com/pl-pl/#entities" target="_blank" rel="noopener noreferrer">P&G</a> jest administratorem Twoich danych oosbowych. Podane przez Ciebie dane będziemy wykorzystywać w celu dostarczenia Ci spersonalizowanych treści za pośrednictwem wiadomości email oraz kanałów komunikacji online, jeśli wyraziłeś na to zgodę. Zapoznaj się z naszą <a href="https://privacypolicy.pg.com/pl-pl/" target="_blank" rel="noopener noreferrer">Polityką Prywatności</a>, aby dowiedzieć się więcej na temat sposobu, w jaki przetwarzamy Twoje dane.</i>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import DateInput from "@/components/renderers/date-input.vue";

export default {
  name: "survey-pampers",
  components: {
    DateInput,
  },
  props: {
    mandatory: {
      type: Boolean,
      default: false,
    },
    questions: {
      type: Array,
      default: () => [],
    },
    successAction: {
      type: Object,
      default: () => null,
    },
    colorSchema: {
      type: String,
      default: "",
    },
    endpoint: {
      type: String,
    },
  },
  data() {
    return {
      questionModel: ['', ''],
      error: ""
    };
  },
  created() {
    this.$eventHub.$on("sendSurvey-event", this.sendSurvey);
  },
  beforeDestroy() {
    this.$eventHub.$off("sendSurvey-event");
  },
  computed: {
    ...mapGetters(["customerToken", "campaignId", "receiptToken"]),
    cssVars() {
      return {
        "--color": this.colorSchema,
      };
    },
  },
  methods: {
    ...mapActions(["sendSurveyData"]),
    sendSurvey() {
      if (this.questionModel[0] == "yes" && this.questionModel[1] == "") {
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false,
        });
        this.error = "Proszę podać datę urodzenia dziecka";
        return;
      }
      let data = {
        questions: {
          hasKids: this.questionModel[0] == "yes" ? true : false,
          enrichChild1Birthdate: this.questionModel[1],
        },
      };
      // Send data to backend
      data.customerToken = this.customerToken;
      data.receiptToken = this.receiptToken;
      data.campaignId = this.campaignId;
      this.sendSurveyData({ formData: data, endpoint: this.endpoint })
        .then((res) => {
          const fn = this.successAction.fn;
          const params = this.successAction.params;
          this[fn](params, this);
        })
        .catch((err) => {
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: false,
          });
        });
    },
  },
};
</script>
<style lang="scss">
:root {
  --bs-btn-active-bg: #fff;
  --bs-btn-active-color: #1e647d;
  --bs-btn-active-border-color: #1e647d;
}

:root {
  --bs-btn-active-bg: #1e647d; /* Set your color */
}

.btn-outline-primary {
  --bs-btn-active-bg: #1e647d;
  --bs-btn-active-color: #FFF;
  --bs-btn-active-border-color: #1e647d;
  --bs-btn-color: #1e647d;
  --bs-btn-border-color: #1e647d;
}
@media (max-width: 600px) {
  .tg td {
    font-size: 14px;
    padding: 1px;
  }
  .tg th {
    font-size: 14px;
    padding: 1px;
  }
}

.question-wrapper {
  width: 100%;
  color: var(--text-color-2);
  @media (max-width: 991px) {
    width: 90%;
  }
}

.question {
  display: block;
  font-size: 1.2em;
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: bold;
}

a {
  color: blue !important;
  text-decoration: underline !important;
}
</style>
