import axios from 'axios'
import i18n from '../translate'

const APIUrl = "https://js-backend.justsnap.eu/api"//process.env.NODE_ENV dev-

export default {
  isPublic({ state }, pageName) {
    if (pageName.toLowerCase() === 'home') return true
    const page = state.pageProperties.pages.find(page => {
      return page.pageName === pageName
    })
    return page && page.isPublic;
  },
  handleError: ({ commit }, err = { message: '', code: '99' }) => {
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
      err.code = err.response.status
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log(err.request);
    } else {
      // Something happened in setting up the request and triggered an err
      console.log('Unexpected Error', err.message);
    }
    const errors = i18n.t('errors') || []
    let defaultMsg = errors['default']
    console.error("Error:", err)
    if (err.cause) {
      if (errors[err.cause]) {
        return commit('setModalOptions', { type: 'error', isActive: true, message: errors[err.cause] })
      } else {
        return commit('setModalOptions', { type: 'error', isActive: true, message: defaultMsg })
      }
    } else if (err.customMsg && err.code) {
      let errorMessage = errors[err.customMsg] ? errors[err.customMsg] : defaultMsg
      let message = errorMessage + "<br>" + "Code:: " + err.code
      return commit('setModalOptions', { type: 'error', isActive: true, message: message })
    } else {
      if (err.code == '404') {
        let msg = defaultMsg + `<br>Code:: ${err.code}`
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      } else if (err.code == '503') {
        let msg = errors['network'] + `<br>Code:: ${err.code}`
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      } else if (err.code == '502') {
        let msg = errors['network'] + `<br>Code:: ${err.code}`
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      } else if (err.message == 'Network Error') {
        let msg = errors['network'] + `<br>Code:: 50X`
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      } else {
        let msg = defaultMsg + `<br>Code:: ${err.code}`
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      }
    }
  },

  setPageProperties: ({
    commit
  }, properties) => {
    commit('setPageProperties', properties);
  },

  refreshTempId: ({ state, dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      axios.post(`${APIUrl}/centralEuropeTempId`, { campaignId: getters.campaignId, token: state.token, iv: state.iv })
        .then(resp => {
          if (resp.data.response) {
            if (!location.pathname.includes("/iban-reupload")) resolve(resp.data.customerToken)
          } else {
            dispatch('handleError', { cause: 'customerToken', code: 97 })
            reject(resp.data.message)
          }
        })
        .catch(err => {
          dispatch('handleError', { cause: 'customerToken', code: 98 })
          reject(err)
        })
    })
  },
  setComponentStatus: ({ commit }, { component, status }) => {
    commit('setComponentStatus', { component, status });
  },
  sendFormData: ({ dispatch }, data) => {
    return new Promise((resolve, reject) => {

      if (window.nsId) {
        data.formData.nsId = window.nsId;
      }

      axios.post(APIUrl + data.endpoint, { ...data.formData })
        .then(res => {
          if (res.data.response) {
            return resolve(res.data)
          } else {
            if (res.data.invalid_fields) {
              //janrain error
              if (res.data.invalid_fields[0] == 'Email address is already in use.') {
                dispatch('handleError', { cause: 'emailUsed' })
              } else if (res.data.invalid_fields[0] == 'Incorrect username or password. Please try again.') {
                dispatch('handleError', { cause: 'incorrect' })
              } else if (res.data.invalid_fields[0] == 'No account with that email address exists.') {
                dispatch('handleError', { cause: 'noEmail' })
              } else if (res.data.message && res.data.message == 'authorization_code is not valid') {
                dispatch('handleError', { cause: 'invalidCode' })
              } else {
                dispatch('handleError', { customMsg: res.data.invalid_fields })
              }
            } else if (res.data.message) {
              let customMsg = res.data.message
              let code = 1
              if(res.data.code){
                code = res.data.code
              } else if (res.data.error){
                code = res.data.error
              }
              if (res.data.message.includes('Missing required field')) {
                if (res.data.message.includes('email')) {
                  code = '99'
                } else if (res.data.message.includes('password')) {
                  code = '98'
                } else if (res.data.message.includes('customerToken')) {
                  code = '97'
                } else if (res.data.message.includes('receiptToken')) {
                  code = '96'
                }
              }
              dispatch('handleError', { customMsg, code })
            } else {
              dispatch('handleError', { code: res.data })
            }
            return reject(res.data)
          }
        })
        .catch(err => {
          dispatch('handleError', err)
          reject(err)
        })
    })
  },

  uploadProductPhotos({ getters, dispatch }, data) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('productPhoto', data.image, 'productPhoto.png')
      formData.append('customerToken', getters.customerToken)
      formData.append('campaignId', getters.campaignId)
      formData.append('receiptToken', data.receiptToken)

      if (data.customerToken == 1) {
        formData.append('token', data.customerToken)
      }

      axios.post(APIUrl + data.endpoint, formData)
        .then(res => {
          return resolve(res.data)
        })
        .catch(err => {
          dispatch('handleError', err)
          reject(err)
        })
    })
  },

  generateCode({ dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      data.formData.customerToken = state.customerToken
      data.formData.campaignId = state.campaignId
      data.formData.receiptToken = state.receiptToken
      axios.post(APIUrl + data.endpoint, data.formData)
        .then(res => {
          if (res.data.response) {
            return resolve(res.data)
          } else {
            dispatch('handleError', { cause: 'generateCode' })
            return reject(res.data)
          }
        })
        .catch(err => {
          dispatch('handleError', err)
          reject(err)
        })
    })
  },

  verifyCode({ dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      data.formData.customerToken = state.customerToken
      data.formData.campaignId = state.campaignId
      data.formData.receiptToken = state.receiptToken

      axios.post(APIUrl + data.endpoint, data.formData)
        .then(res => {
          if (res.data.response) {
            return resolve(res.data)
          } else {
            dispatch('handleError', { cause: 'wrongCode' })
            return reject(res.data)
          }
        })
        .catch(err => {
          dispatch('handleError', err)
          reject(err)
        })
    })
  },

  sendProducts({ dispatch, state, getters }, data) {
    return new Promise((resolve, reject) => {
      data.formData.customerToken = state.customerToken
      data.formData.receiptToken = state.receiptToken
      data.formData.campaignId = getters.campaignId

      axios.post(APIUrl + data.endpoint, data.formData)
        .then(res => {
          if (res.data.response) {
            resolve(res.data)
          } else {
            dispatch('handleError', { customMsg: res.data.message })
            reject(res.data)
          }
        })
        .catch(err => {
          dispatch('handleError', err)
          reject(err)
        })
    })
  },

  uploadReceipt({ getters, commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      if (data.receiptToken) {
        formData.append('receiptToken', data.receiptToken)
      }

      formData.append('userToken', data.customerToken)
      formData.append('receipt', data.image, 'receipt.png')
      formData.append('subCampaignId', getters.campaignId)
      formData.append('campaignId', data.campaignId)

      if (data.token) {
        formData.append('token', data.token)
      }
      return axios.post(`${data.apiUrl}`, formData)
        .then(resp => {
          if (resp.data.response !== 'ERR') {
            // If reference number is returned, set reference number
            if (resp.data.referenceNumber) {
              const data = {
                field: 'formData',
                value: { 'referenceNumber': resp.data.referenceNumber }
              }
              commit('setField', data)
            }

            commit('setReceiptToken', resp.data.receiptToken)
            resolve(resp.data.receiptToken);
          } else {
            dispatch('handleError', resp)
          }
        })
        .catch(err => {
          dispatch('handleError', { cause: 'invalidReceipt' } )
          reject(err)
        })
    })
  },

  searchProductByBarcode({ state, dispatch, commit }, { barcode, maxProductCount, endpoint }) {
    if (state.scannedProducts && state.scannedProducts.find(x => {
      return x.ean === barcode
    })) {
      commit('setModalOptions', { type: 'error', isActive: true, message: i18n.t('You have already added this product.') });
      return false;
    }
    state.currentBarcode = barcode;
    const isAllowed = maxProductCount ? maxProductCount > state.scannedProducts.length : true;
    if (/^[0-9]+$/.test(barcode) && isAllowed) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${APIUrl + endpoint}`, {
            campaignId: state.campaignId,
            barcode: barcode
          })
          .then(res => {
            let status = res.data.result.length > 0 ? 'success' : 'not_found';
            commit("setSearchStatus", status);
            commit("setProducts", res.data.result);

            if (status === 'not_found') {
              // The barcode is not P&G
              commit('setScannerError', 'Der Barcode scheint nicht zu einem P&G Produkt zu gehören. Glauben Sie, dass es sich um einen Fehler handelt?')
              commit('setErrorActivate', true)
            } else {
              commit('setErrorActivate', false)
            }
            resolve(res.data);
          }).catch(err => {
            reject(err)
          })
      });
    } else {
      if (!isAllowed) {
        console.log("max", maxProductCount)
        commit('setModalOptions', {
          type: 'error', isActive: true, message: i18n.t('You have already added the maximum number of products.')
        });
        commit("setSearchStatus", null);
      } else {
        commit("setSearchStatus", 'not_found');
        // The barcode is invalid
        commit('setScannerError', 'Der Barcode ist ungültig. Bitte versuchen Sie erneut, den Barcode einzugeben oder zu scannen. Glauben Sie, dass es sich um einen Fehler handelt?')
        commit('setErrorActivate', true)
      }
    }
  },
  isUpdateIban: ({ dispatch }, data) => {
    return new Promise((resolve, reject) => {
      axios.get(`${APIUrl}/ibanUpdateCheck/${data.customerToken}/${data.campaignId}`)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch('handleError', err)
          reject(err)
        })
    })
  },
  check72Hours({ getters }, data) {
    const formData = {}
    formData.receiptToken = data
    formData.campaignId = getters.campaignId
    return new Promise((resolve, reject) => {
      axios.post(`${APIUrl}/check72Hours`, formData)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  lastStep({ getters, dispatch }) {
    const formData = {}
    formData.receiptToken = getters.receiptToken
    formData.customerToken = getters.customerToken
    formData.campaignId = getters.campaignId
    return new Promise((resolve, reject) => {
      axios.post(`${APIUrl}/centralEuropeLastStep`, formData)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch('handleError', err)
          reject(err)
        })
    })
  },
  sendSurveyData({ getters, dispatch }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${APIUrl}/surveyCentralEurope`, data.formData)
        .then(resp => {
          if (resp.data.response) {
            resolve(resp.data)
          } else {
            dispatch('handleError', { cause: 'survey' })
            reject(resp.data)
          }
        })
        .catch(err => {
          dispatch('handleError', err)
          reject(err)
        })
    })
  },
  sendRequest ({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axios.post(APIUrl + data.endpoint, data.formData)
        .then(resp => {
          if (resp.data.response)
            resolve(resp.data)
          else
            reject(resp.data)
        })
        .catch(err => {
          dispatch('handleError', err)
          reject(err)
        })
    })
  }
}
