import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import i18n from '../translate'

Vue.use(VueRouter)
function loadPage (pagePath) {
  return () => {
    return import(`@/${pagePath}`)
  };
}


const goTo = (to, from, next) => {
  console.log("ROUTE", this, from, next)
}
const routes = [
  {
    path: '/sitedown',
    name: 'Sitedown',
    component: loadPage('views/Maintenance.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: loadPage('views/Home.vue')
  },
  {
    path: '/geoLimit',
    name: 'geo',
    component: loadPage('views/GeoLimit.vue')
  },
  {
    path: '/*',
    name: 'anyRootPath',
    component: loadPage('views/Home.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  routes
})

const campaigns = {
  // Poland
  '1379': 'pl',
  '1380': 'pl',
  '1381': 'pl',
  '1382': 'pl',
  '1383': 'pl',
  '1384': 'pl',
  '2364': 'pl',
  '2375': 'pl',
  '2378': 'pl',
  '2380': 'pl',
  '2381': 'pl',
  '2382': 'pl',
  '2386': 'pl',
  '2390': 'pl',
  '2392': 'pl',
  '2393': 'pl',
  '2394': 'pl',
  // Braun PL Test Campaigns
  '1544': 'pl',
  '1545': 'pl',
  '1546': 'pl',
  '1547': 'pl',
  '1549': 'pl',
  '1548': 'pl',
  '1550': 'pl',
  '1551': 'pl',
  // Braun PL Prod Campaigns
  '1725': 'pl',
  '1724': 'pl',
  '1726': 'pl',
  '1727': 'pl',
  '1729': 'pl',
  '1728': 'pl',
  '1730': 'pl',
  '1731': 'pl',
  // OralB PL Test Campaigns
  '1574': 'pl',
  '1575': 'pl',
  '1576': 'pl',
  '1577': 'pl',
  // OralB PL Prod Campaigns
  '1711': 'pl',
  '1712': 'pl',
  '1713': 'pl',
  '1714': 'pl',
  // Fairy PL
  '2079': 'pl',
  '2080': 'pl',
  // MBCI PL
  '2226': 'pl',
  '2227': 'pl',
  '2228': 'pl',
  '2229': 'pl',
  // Voucher PL
  '2401': 'pl',
  '2402': 'pl',
  '2403': 'pl',
  '2404': 'pl',
  // Voucher DEV PL
  '3229': 'pl',
  '3230': 'pl',
  '3231': 'pl',
  '3232': 'pl',
  '2435': 'pl',
  '2436': 'pl',
  '2437': 'pl',
  '2438': 'pl',
  '2447': 'pl',
  '2448': 'pl',
  '2449': 'pl',
  '2450': 'pl',
  '2451': 'pl',
  '2452': 'pl',
  '2223': 'pl',
  '2646': 'pl',
  '2779': 'pl',
  '2780': 'pl',

  // Czech
  '1362': 'cs',
  '2367': 'cs',
  '2430': 'cs',
  '1568': 'cs',
  '1569': 'cs',
  '1570': 'cs',
  '1571': 'cs',
  '1572': 'cs',
  '1573': 'cs',
  '1738': 'cs',
  '1739': 'cs',
  '1740': 'cs',
  '1741': 'cs',
  '1742': 'cs',
  '1743': 'cs',
  '1578': 'cs',
  '1579': 'cs',
  '1580': 'cs',
  '1581': 'cs',
  '1715': 'cs',
  '1716': 'cs',
  '1717': 'cs',
  '1718': 'cs',
  '2211': 'cs',
  '2212': 'cs',
  '2213': 'cs',
  '2439': 'cs',
  '2440': 'cs',
  '2441': 'cs',
  '2442': 'cs',
  '2613': 'cs',
  '2614': 'cs',
  '2615': 'cs',
  '2616': 'cs',
  '2617': 'cs',
  '1907': 'cs',
  '2809': 'cs',
  '2808': 'cs',
  '2807': 'cs',
  '2806': 'cs',
  '30067': 'cs',


  // Slovakia
  '1361': 'sk',
  '2431': 'sk',
  '2370': 'sk',
  '1562': 'sk',
  '1563': 'sk',
  '1564': 'sk',
  '1565': 'sk',
  '1566': 'sk',
  '1567': 'sk',
  '1732': 'sk',
  '1733': 'sk',
  '1734': 'sk',
  '1735': 'sk',
  '1736': 'sk',
  '1737': 'sk',
  '1582': 'sk',
  '1583': 'sk',
  '1584': 'sk',
  '1585': 'sk',
  '1719': 'sk',
  '1720': 'sk',
  '1721': 'sk',
  '1722': 'sk',
  '2214': 'sk',
  '2215': 'sk',
  '2216': 'sk',
  '2443': 'sk',
  '2444': 'sk',
  '2445': 'sk',
  '2446': 'sk',
  '1908': 'sk',
  '2618': 'sk',
  '2619': 'sk',
  '2620': 'sk',
  '2621': 'sk',
  '2622': 'sk',
  '2813': 'sk',
  '2812': 'sk',
  '2811': 'sk',
  '2810': 'sk',
  '30068': 'sk',
}

const VUE_APP_SITE_DOMAIN = process.env.VUE_APP_SITE_DOMAIN
let toLoop = true;
router.beforeEach((to, from, next) => {
  if (to.name === "security" && toLoop) {
    toLoop = false;
    // router.push(".wellknown/security.txt")
    window.location.href = `${VUE_APP_SITE_DOMAIN}.wellknown/security.txt`;
  } else {
    const queries = to.query
    const campaignId = queries.campaignId
    // console.log("CAMPAIGN ID", campaignId, queries, queries.page, to.params.pathMatch)
    if (to.params.pathMatch == 'moneyback') {
      // // Try to load the campaign file if it exists
      let campaign = JSON.parse(
        JSON.stringify(require(`../store/moneyback/moneyback${campaigns[campaignId]}`).default).replaceAll(
          "{{campaignId}}", campaignId
        )
      );
      const shared = JSON.parse(
        JSON.stringify(require("../store/shared").default).replaceAll(
          "{{campaignPath}}",
          campaign.lang
        )
      );
      campaign.pageProperties.pages.push(...shared.pageProperties.pages);
      i18n.locale = campaign.lang
      store.replaceState(campaign);
    } else if (to.params.pathMatch == 'voucher' || to.params.pathMatch == 'voucher/') {
      // // Try to load the campaign file if it exists
      let campaign = JSON.parse(
        JSON.stringify(require(`../store/voucher/voucher${campaigns[campaignId]}`).default).replaceAll(
          "{{campaignId}}", campaignId
        )
      );
      const shared = JSON.parse(
        JSON.stringify(require("../store/shared").default).replaceAll(
          "{{campaignPath}}",
          campaign.lang
        )
      );
      campaign.pageProperties.pages.push(...shared.pageProperties.pages);
      i18n.locale = campaign.lang
      store.replaceState(campaign);
    } else if (queries.campaignId && queries.campaignId != '') {
      // Try to load the campaign file if it exists (Maybe it is a custom campaign)
      try {
        let campaign = require(`../store/campaigns/${campaignId}`).default
        const shared = JSON.parse(
          JSON.stringify(require("../store/shared").default).replaceAll(
            "{{campaignPath}}",
            campaign.lang
          )
        );
        campaign.pageProperties.pages.push(...shared.pageProperties.pages);
        i18n.locale = campaign.lang
        store.replaceState(campaign);
      } catch (e) {
        // If the campaign file doesn't exist, try to load the shared campaign
        if (campaigns[campaignId]) {
          let campaign = JSON.parse(
            JSON.stringify(require(`../store/shared${campaigns[campaignId]}`).default).replaceAll(
              "{{campaignId}}", campaignId
            )
          );
          const shared = JSON.parse(
            JSON.stringify(require("../store/shared").default).replaceAll(
              "{{campaignPath}}",
              campaign.lang
            )
          );
          campaign.pageProperties.pages.push(...shared.pageProperties.pages);
          i18n.locale = campaign.lang
          store.replaceState(campaign);
        } else {
          next()
        }
      }
    }
  
    /**
       * Check the campaign ending date, and if its true,
       * redirect to 'campaign-is-over' page
    */
    const endDate = new Date(store.state.campaignEndDate).toLocaleString('en-US', { timeZone: 'Europe/Berlin' })
    const currentTime = new Date().toLocaleString('en-US', { timeZone: 'Europe/Berlin' });
    const dayInSeconds = 86400; // If we need to include the ending day
    let camEnded = (new Date(endDate).getTime() / 1000 + dayInSeconds) < (new Date(currentTime).getTime() / 1000)
    let allowedPages = ['campaign-is-over', 'terms', 'faq', 'receipt-reupload', 'product-reupload', 'iban-reupload', 'error']
    if (camEnded) {
      if (allowedPages.includes(queries.page)) {
        next()
      } else {
        to.query.page = 'campaign-is-over'
        next()
      }
    } else {
      next ()
    }
  }
})

export default router
