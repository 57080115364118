
export default {
  isEditMode: true,
  pageProperties: {
    testing: true,
    "pages": [
      { // Barcode scanner page
        "pageName": "home",
        isPublic: true,
        pageId: 10033,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "sectionId": "fu30zq",
            "fields": [
              {
                "fieldType": "refresh-token",
                "fieldId": "ref1000",
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "Specify barcode number",
                        classes: 'text-left',
                        styles: {
                          "font-weight": "700",
                          "font-size": "26px",
                          "line-height": "35px"
                        }
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Please scan or enter manually the barcode from your product",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Receipt uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "alignItems": "center",
                          "justifyContent": "space-around",
                          "marginBottom": "60px"
                        },
                        "classes": "receipt-bucket",
                        "fields": [
                          { // Barcode Scanner
                            "fieldType": "barcode-scanner",
                            "fieldId": "barcodeScanner",
                            "properties": {
                              "grid": {
                                "columns": 12
                              },
                              "successAction": {
                                "fn": "goToPage",
                                "params": "receipt"
                              },
                              "endpoint": "/submitProducts",
                              "texts": {
                                receiptUpload: 'Upload',
                                receiptUploadDescription: 'Upload barcode image',
                                receiptUploadSubDescription: 'Drag & Drop',
                                wrongFormat: 'The file format is not supported. It will only .jpeg, .png and .pdf file accepted.',
                                fileSizeLimit: 'The file is too large. Only files up to 16 MB are supported.',
                                barcodeScan: 'SCAN',
                                stop: 'STOP',
                                barcodeScanDescription: 'Click here and scan the barcode.',
                                barcodeScanSubDescription: '',
                                barcodeAdd: 'APPLY',
                                barcodeAddDescription: 'Enter barcode number',
                                notParticipating: 'The barcode does not appear to belong to a P&G product. Do you think this is an error?',
                                notParticipatingVideo: 'Unfortunately, the product does not appear to be participating in the promotion. Please try to enter the barcode in the field above.',
                                timedOut: 'Unfortunately no valid barcode can be recognized. Please try to enter the barcode in the field above.',
                                cameraNotFound: 'Please turn on your camera',
                                invalidBarCode: 'The barcode number is invalid. Try it again.'
                              },
                              "cameraTimeOut": 20000,
                              "maxProductCount": 10
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Continue",
                              classes: "btn-primary",
                              "requires": ["barcodeScanner"],
                              "action": {
                                "fn": "sendBarcodeProducts",
                                "params": ""
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Receipt Upload
        "pageName": "receipt",
        pageId: 100,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "classes": "container",
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px",
                    "marginTop": "80px"
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "Upload a receipt",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "In this step, please upload the proof of purchase of your products.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Receipt uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "alignItems": "center",
                          "justifyContent": "space-around",
                          "marginBottom": "60px",
                          "marginTop": "50px"
                        },
                        "classes": "receipt-bucket",
                        "fields": [
                          { // Recipt upload
                            "fieldType": "receipt-processor",
                            "fieldId": "klvakj",
                            "properties": {
                              "grid": {
                                "columns": "6",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "successAction": {
                                "fn": "goToPage",
                                "params": "sms-page"
                              },
                              texts: {
                                choose: 'UPLOAD',
                                text1: 'Drop your photo here or select file.',
                                text2: 'Only JPG, PNG and PDF files are supported Maximum file size: 10MB',
                                wrongFormat: 'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.',
                                fileSizeLimit: 'This file is too big. Only files up to 10MB can be uploaded.'
                              },
                              editTexts: {
                                crop: "Crop",
                                erase: "Erase",
                                drag: "Drag",
                                zoomIn: "Zoom in",
                                zoomOut: "Zoom out"
                              },
                              colorSchema: "#3E3D40",
                              "phrases": [
                                "Please erase your personal data with the eraser tool.",
                                "Please frame the receipt / invoice in the gray rectangle."
                              ],
                              "cornerDetectionPhrases": [
                                "Please erase your personal data with the eraser tool.",
                                "Please frame the receipt / invoice in the gray rectangle."
                              ]
                            }
                          },
                          { // Receipt Info
                            "fieldType": "empty-bucket",
                            "fieldId": "vdf932fd",
                            "properties": {
                              "grid": {
                                "columns": "6"
                              },
                              "classes": "addMarginLeft",
                              "fields": [
                                {
                                  "fieldType": "receipt-information",
                                  "fieldId": "5mq1p6",
                                  "properties": {
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": 12
                                    },
                                    shouldDisplayFull: true,
                                    "texts": {
                                      "descText": `The following information must be visible:`,
                                      "descSubText": `Folded or scanned receipts or invoices will not be accepted`,
                                      "correct": "Valid",
                                      "notReadabe": "Hard to read",
                                      "folded": "Folded",
                                      "cropped": "Cut off"
                                    },
                                    "hideExamples": false,
                                    "steps": [
                                      { // Retailer
                                        "text": "Retailer / Shop",
                                        "label": "A"
                                      },
                                      {
                                        "text": "Date and time of purchase",
                                        "label": "B"
                                      },
                                      {
                                        "text": "Campaign product and its price",
                                        "label": "C"
                                      },
                                      {
                                        "text": "Total sum of the receipt / invoice and applied VAT",
                                        "label": "D"
                                      },
                                      {
                                        "text": "Receipt / Invoice ID (not the bank transaction ID)",
                                        "label": "E"
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Profiling Checkbox
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "marginBottom": "30px"
                        },
                        fields: [
                          { // Profiling Checkbox
                            "fieldType": "profiling-checkbox",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 12
                              },
                              id: "profiling",
                              name: "profiling",
                              label: `profiling-text`,
                              colorSchema: "#7879F1",
                              endpoint: '/getProfilingId'
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Continue",
                              classes: "btn-primary",
                              "requires": ["klvakj"],
                              "action": {
                                "fn": "sendReceipt",
                                "params": ""
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // SMS VERIFICATION
        "pageName": "sms-page",
        pageId: 110,
        "sections": [
          {
            "sectionId": "ei5u4c",
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": "12"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px",
                    "marginTop": "80px"
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "Confirm your identity and claim your refund",
                        classes: 'heading text-left'
                      }
                    },
                    { // SUBHeading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Verify your identity by entering your phone number here. It is only used once for verification and not for advertising purposes. After the action, your phone number will be deleted by us.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // SMS
                      "fieldType": "sms-verification",
                      "fieldId": "c6yysh",
                      "properties": {
                        "endpoint": "/mobileAuth",
                        "inputWidth": "720px",
                        "successAction": {
                          "fn": "goToPage",
                          "params": "sms-code"
                        },
                        options: [
                          {
                            name: "Poland",
                            code: "pl",
                            phoneCode: "+48"
                          }
                        ],
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": 6,
                          marginBottom: "50px"
                        }
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: `Procter & Gamble is data controller of the personal data you provide. Data related to your bank account number will be processed for the purpose of providing you with money refund. Your phone number will be processed for the purpose of preventing frauds and abuses. You can exercise your data protection rights or change your preferences and subscriptions by clicking here. You can find out more about how we use your data in our Privacy Policy.`,
                        classes: 'consent-text'
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "marginBottom": "30px",
                          justifyContent: "center",
                          alignItems: "center"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Back",
                              classes: "btn-secondary",
                              "action": {
                                "fn": "goToPage",
                                "params": "receipt"
                              }
                            }
                          },
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Continue",
                              classes: "btn-primary",
                              requires: ['phoneNumber'],
                              "action": {
                                "fn": "sendSms",
                                "params": ""
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // SMS Receiving
        "pageName": "sms-code",
        pageId: 111,
        "sections": [
          {
            "sectionId": "ei5u4c",
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": "12"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px",
                    "marginTop": "80px"
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "Confirm your identity and claim your refund",
                        classes: 'heading text-left'
                      }
                    },
                    { // SUBHeading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "We have sent you a code via SMS to the phone number you provided. Please enter this in the field below. If you did not receive a code, please change your phone number and try again",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // SMS
                      "fieldType": "sms-receiving",
                      "fieldId": "c6yysh",
                      "properties": {
                        "endpoint": "/mobileVerification",
                        "keepInState": ["referenceNumber"],
                        "inputWidth": "720px",
                        "successAction": {
                          "fn": "goToPage",
                          "params": "thank-you"
                        },
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": 4
                        }
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "marginBottom": "30px",
                          justifyContent: "center",
                          alignItems: "center"
                        },
                        fields: [
                          { // Change mobile
                            "fieldType": "anchor",
                            "fieldId": "fckzg7",
                            "properties": {
                              "text": 'Change the mobile number',
                              "action": {
                                "fn": "goToPage",
                                "params": "sms-page"
                              },
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": 4,
                                marginBottom: "50px"
                              },
                              "styles": {
                                "text-align": "left",
                                "color": "var(--color-4)",
                                "font-size": "12px",
                                "line-height": "14px"
                              }
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "marginBottom": "30px",
                          justifyContent: "center",
                          alignItems: "center"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Back Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Back",
                              classes: "btn-secondary",
                              "action": {
                                "fn": "goToPage",
                                "params": "sms-page"
                              }
                            }
                          },
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Continue",
                              classes: "btn-primary",
                              requires: ['code'],
                              "action": {
                                "fn": "verifySms",
                                "params": ""
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Thank you Page
        "pageName": "thank-you",
        pageId: 113,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px",
                    "marginTop": "80px"
                  },
                  fields: [
                    { // Key visual
                      "fieldType": "key-visual",
                      "fieldId": "key100",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        styles: {
                          "padding": '0 0 0 0'
                        },
                        noContainer: true,
                        desktopImageUrl: "assets/images/banner/pl/banner_desktop.png",
                        tabletImageUrl: "assets/images/banner/pl/banner_tablet.png",
                        mobileImageUrl: "assets/images/banner/pl/banner_mobile.png"
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: "30px"
                        },
                        text: "Your receipt / invoice and your data have been successfully sent to us and are being checked at the moment.As soon as we have checked your data, you will receive either a confirmation e-mail or an e-mail asking us if your data was incomplete or your proof of purchase could not be recorded. Please keep your reference number.",
                        classes: 'text text-center'
                      }
                    },
                    { // Clipboard
                      "fieldType": "clipboard",
                      "fieldId": "ref123",
                      "properties": {
                        "grid": {
                          "columns": 6,
                          "padding-left": "0",
                          "padding-right": "0",
                          "marginBottom": "30px"
                        },
                        "title": "Reference number",
                        "disabledInput": true,
                        "variables": {
                          "referenceNumber": "referenceNumber"
                        }
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: "50px"
                        },
                        text: "The verification can take up to 72 hours. We ask for your patience.",
                        classes: 'text text-center'
                      }
                    },
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Back to campaign page",
                        "grid": {
                          "columns": 6,
                          "alignItems": "center",
                          "justifyContent": "center",
                          marginBottom: "50px"
                        },
                        "classes": "btn-primary",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "https://www.everydayme.pl/oferty"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Receipt Re Upload
        "pageName": "receipt-reupload",
        isPublic: true,
        pageId: 119,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "classes": "container",
            "fields": [
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    },
                    {
                      query: "token",
                      state: "receiptToken"
                    }
                  ]
                }
              },
              { // 72 Hours check
                "fieldType": "checkReceiptToken",
                "fieldID": "dksnfsdkjf",
                properties: {
                  grid: { columns: 12 }
                }
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px",
                    "marginTop": "80px"
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "Please upload your receipt again here",
                        classes: 'heading text-left'
                      }
                    },
                    { // Receipt uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "alignItems": "center",
                          "justifyContent": "space-around",
                          "marginBottom": "60px",
                          "marginTop": "50px"
                        },
                        "classes": "receipt-bucket",
                        "fields": [
                          { // Recipt upload
                            "fieldType": "receipt-processor",
                            "fieldId": "recReupload",
                            "properties": {
                              "grid": {
                                "columns": "6",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "successAction": {
                                "fn": "goToPage",
                                "params": "thank-you"
                              },
                              texts: {
                                choose: 'UPLOAD',
                                text1: 'Drop your photo here or select file.',
                                text2: 'Only JPG, PNG and PDF files are supported Maximum file size: 10MB',
                                wrongFormat: 'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.',
                                fileSizeLimit: 'This file is too big. Only files up to 10MB can be uploaded.'
                              },
                              editTexts: {
                                crop: "Crop",
                                erase: "Erase",
                                drag: "Drag",
                                zoomIn: "Zoom in",
                                zoomOut: "Zoom out"
                              },
                              reUpload: true,
                              colorSchema: "#3E3D40",
                              "phrases": [
                                "Please erase your personal data with the eraser tool.",
                                "Please frame the receipt / invoice in the gray rectangle."
                              ],
                              "cornerDetectionPhrases": [
                                "Please erase your personal data with the eraser tool.",
                                "Please frame the receipt / invoice in the gray rectangle."
                              ]
                            }
                          },
                          { // Receipt Info
                            "fieldType": "empty-bucket",
                            "fieldId": "vdf932fd",
                            "properties": {
                              "grid": {
                                "columns": "6"
                              },
                              "classes": "addMarginLeft",
                              "fields": [
                                {
                                  "fieldType": "receipt-information",
                                  "fieldId": "5mq1p6",
                                  "properties": {
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": 12
                                    },
                                    shouldDisplayFull: true,
                                    "texts": {
                                      "descText": `The following information must be visible:`,
                                      "descSubText": `Folded or scanned receipts or invoices will not be accepted`,
                                      "correct": "Valid",
                                      "notReadabe": "Hard to read",
                                      "folded": "Folded",
                                      "cropped": "Cut off"
                                    },
                                    "hideExamples": false,
                                    "steps": [
                                      { // Retailer
                                        "text": "Retailer / Shop",
                                        "label": "A"
                                      },
                                      {
                                        "text": "Date and time of purchase",
                                        "label": "B"
                                      },
                                      {
                                        "text": "Campaign product and its price",
                                        "label": "C"
                                      },
                                      {
                                        "text": "Total sum of the receipt / invoice and applied VAT",
                                        "label": "D"
                                      },
                                      {
                                        "text": "Receipt / Invoice ID (not the bank transaction ID)",
                                        "label": "E"
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Continue",
                              classes: "btn-primary",
                              "requires": ["recReupload"],
                              "action": {
                                "fn": "sendReceipt",
                                "params": ""
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Product Re Upload
        "pageName": "product-reupload",
        isPublic: true,
        pageId: 120,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    },
                    {
                      query: "token",
                      state: "receiptToken"
                    }
                  ]
                }
              },
              { // 72 Hours check
                "fieldType": "checkReceiptToken",
                "fieldID": "dksnfsdkjf",
                properties: {
                  grid: { columns: 12 }
                }
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px",
                    "marginTop": "80px"
                  },
                  "classes": "container",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "Please upload your product photo here",
                        classes: 'heading text-left'
                      }
                    },
                    { // Product Photo uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          {
                            "fieldType": "product-upload",
                            "fieldId": "prodPhoto",
                            "properties": {
                              texts: {
                                choose: 'UPLOAD',
                                text1: 'Drop your photo here or select file.',
                                text2: 'Only JPG and PNG files are supported Maximum file size: 10MB',
                                wrongFormat: 'This file format is not supported. Only .jpeg and .png files are accepted.',
                                fileSizeLimit: 'This file is too big. Only files up to 10MB can be uploaded.'
                              },
                              "colorSchema": "var(--color2)",
                              "grid": {
                                "columns": "6",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "reUpload": true,
                              "endpoint": "/uploadProductPhoto",
                              "successAction": {
                                "fn": "goToPage",
                                "params": "thank-you"
                              }
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Continue",
                              classes: "btn-primary",
                              "requires": ["prodPhoto"],
                              "action": {
                                "fn": "sendProductPhotos",
                                "params": "prodPhoto"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Iban reuploader
        "pageName": "iban-reupload",
        isPublic: true,
        pageId: 123,
        "requires": [
          1
        ],
        "sections": [
          { // Section
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "marginTop": "50px"
            },
            "fields": [
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "customerToken",
                      state: "customerToken"
                    },
                    {
                      query: "campaignId",
                      state: "campaignId"
                    }
                  ]
                }
              },
              {
                "fieldType": "iban-reupload",
                "fieldId": "ij3411"
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "flexDirection": "column",
                    "marginBottom": "60px"
                  },
                  classes: "maxWidthOverride transparentBg",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 6,
                          marginBottom: "30px"
                        },
                        text: "Provide bank details",
                        classes: 'heading text-center text-uppercase overrideMarginBot'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 6,
                          marginBottom: "30px"
                        },
                        text: "Please enter the first name and last name of the account holder exactly as they would be given at the bank.",
                        classes: 'text-center overrideMarginBot',
                        styles: {
                          "color": "rgba(62, 62, 64, 0.8)",
                          "font-weight": "500"
                        }
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 6,
                          marginBottom: "50px"
                        },
                        text: "Please do not try to enter the same bank details that the first attempt at transfer failed with.",
                        classes: 'text text-center'
                      }
                    },
                    { // Form
                      "fieldType": "form-component",
                      "fieldId": "bank1Nf0",
                      "properties": {
                        "successAction": {
                          "fn": "goToPage",
                          "params": "thank-you-iban"
                        },
                        "endpoint": "/updateIBANBigBoy",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": 6,
                          marginBottom: "20px"
                        },
                        "styles": {
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": 10,
                                marginBottom: "50px"
                              },
                              "classes": "form-wrapper",
                              "fields": [
                                {
                                  "fieldType": "empty-bucket",
                                  "fieldId": "f934if",
                                  "properties": {
                                    "grid": {
                                      "columns": 12,
                                      "justifyContent": "center",
                                      "alignItems": "center"
                                    },
                                    "classes": "inner-wrapper",
                                    "fields": [
                                      { // First name
                                        "fieldId": "tp6zfb",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "firstname",
                                          "id": "firstname",
                                          "label": "Account holder first name",
                                          "validation": "required|ibanName",
                                          "labelOnTop": true,
                                          "placeholder": "Account holder first name",
                                          "text": "",
                                          "grid": {
                                            "columns": 6
                                          }
                                        }
                                      },
                                      { // Last name
                                        "fieldId": "p55fao",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "lastname",
                                          "id": "lastname",
                                          "validation": "required|ibanName",
                                          "labelOnTop": true,
                                          "label": "Account holder last name",
                                          "placeholder": "Account holder last name",
                                          "text": "",
                                          "grid": {
                                            "columns": 6
                                          }
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  "fieldType": "empty-bucket",
                                  "fieldId": "f934if",
                                  "properties": {
                                    "grid": {
                                      "columns": 12,
                                      "justifyContent": "center",
                                      "alignItems": "center"
                                    },
                                    "fields": [
                                      {
                                        "fieldId": "h9arlr",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "iban",
                                          "id": "iban",
                                          "validation": "required|iban:PL",
                                          "labelOnTop": true,
                                          "label": "IBAN",
                                          "placeholder": "Enter your IBAN number",
                                          "text": "",
                                          "isUpperCase": true,
                                          "grid": {
                                            "columns": 10
                                          }
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Continue",
                              classes: "btn-primary",
                              requires: ['firstname', 'lastname', 'iban'],
                              "action": {
                                "fn": "sendForm",
                                "params": "bank1Nf0"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Iban thank you page
        "pageName": "thank-you-iban",
        pageId: 124,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "marginTop": "50px"
            },
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  classes: "container",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Thank you!!",
                        classes: 'heading text-center'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: "50px"
                        },
                        text: "Thank you for resubmitting your bank details.<br>So there should now be a successful transfer. If you have any questions related to your bank details, please contact link.",
                        classes: 'text text-center'
                      }
                    },
                    { // Continue Button
                      "fieldType": "button-component",
                      "fieldId": "but100",
                      properties: {
                        grid: {
                          columns: 8
                        },
                        text: "Back to campaign page",
                        classes: "btn-primary",
                        "action": {
                          "anchor": true,
                          "link": "#homepage"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    ],
    "header": {
      "fieldType": "navbar",
      "fieldId": "cbx34t",
      "properties": {
        "grid": {
          "columns": 12
        },
        "logo": {
          imageSource: "assets/images/svg/everydayLogo.svg",
          url: '/',
          alt: 'Everyday Logo'
        },
        "favicon": {
          "imageSource": "assets/images/svg/pglogo.svg"
        },
        "styles": {
          "box-shadow": "rgba(0, 0, 0, .11) 0 2px 4px 0"
        },
        "meta": [
          { "name": "title", "content": "PG Central Europe" },
          {
            "name": "description",
            "content": ``
          },
          {
            "name": "keywords",
            "content": `PG Central Europe`
          }
        ]
      }
    },
    "footer": {
    },
    "url": "http://justsnap.everydayme.pl/",
    "name": "test-campaign"
  },
  componentStatus: {},
  modal: {
    type: 'error',
    message: 'Ein Fehler ist aufgetreten',
    isActive: false
  },
  campaignId: "2779",
  iv: "",
  token: "",
  customerToken: '',
  receiptToken: '',
  receiptImage: '',
  referenceNumber: '',
  formData: new FormData(),
  campaignEndDate: '2025-01-01',
  scannerActive: false,
  file: {},
  barcode: {
    code: '',
    format: ''
  },
  productCode: '',
  searchStatus: null,
  imgList: [],
  errorMessage: "",
  errorActivate: false,
  scannedProducts: [],
  frontAndBack: false,
  countryCode: '',
  tel: '',
  consumerId: '',
  lang: 'pl',
  profiling: false,
  uniqueCode: '',
}
