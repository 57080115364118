<template>
  <ValidationObserver ref="form">
    <form v-if="!loading" :id="fieldId">
      <div v-if="state==='success'">
        <template v-for="field in fields">
          <componentRenderer
            :class="`col-lg-${field.properties.grid.columns}`"
            :field="field"
            style="margin-top: 20px"
            v-on:component-updated="updated"
            v-bind:key="field.id"
          />
        </template>
      </div>
      <div v-else-if="state==='notFound'">
        <template v-for="field in notFoundFields">
          <componentRenderer
            :class="`col-lg-${field.properties.grid.columns}`"
            :field="field"
            style="margin-top: 80px"
            v-on:component-updated="updated"
            v-bind:key="field.id"
          />
        </template>
      </div>
      <div v-else-if="state==='expired'">
        <template v-for="field in expiredFields">
          <componentRenderer
            :class="`col-lg-${field.properties.grid.columns}`"
            :field="field"
            style="margin-top: 80px"
            v-on:component-updated="updated"
            v-bind:key="field.id"
          />
        </template>
      </div>
      <div v-else>
        <template v-for="field in failFields">
          <componentRenderer
            :class="`col-lg-${field.properties.grid.columns}`"
            :field="field"
            style="margin-top: 80px"
            v-on:component-updated="updated"
            v-bind:key="field.id"
          />
        </template>
      </div>
      <!-- <button @click="submit">SUBMIT</button> -->
    </form>
  </ValidationObserver>
</template>
<script>
import componentRenderer from "@/components/component-renderer.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "reclaim-bucket",
  components: { componentRenderer },
  props: {
    fieldId: String,
    requires: Array,
    successAction: {
      type: Object,
      default: () => {
        return null
      }
    },
    endpoint: {
      type: String,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    failFields: {
      type: Array,
      required: true
    },
    notFoundFields: {
      type: Array,
      required: true
    },
    expiredFields: {
      type: Array,
      required: true
    },
    grid: {
      type: Object
    }
  },
  data: () => {
    return {
      state: "",
      loading: true
    };
  },
  computed: {
    ...mapGetters(["campaignId"])
  },
  methods: {
    ...mapActions(['sendRequest'])
  },
  mounted () {
    if (this.$route.query && this.$route.query.token) {
      const formData = {
        receiptToken: this.$route.query.token,
        campaignId: this.campaignId
      }
      this.sendRequest({ endpoint: this.endpoint, formData })
      .then(res => {
        if (res.response && !res.errorCode) {
          this.loading = false
          this.state = "success"
        }  else {
          this.goToPage('expired-page', this)
        }
      })
    }
  }
};
</script>
