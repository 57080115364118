<template>
  <div
    class="m-0 checkboxWrapper"
    :style="cssVars"
    v-if="showCheckbox"
  >
    <div
      class="wrapper"
      :style="styles"
    >
      <label
        class="box-container"
        :for="`checkbox_${id}`"
      >
        <span v-html="$t(label)"></span>
        <input
          v-model="localChecked"
          :name="name"
          :checked="checked"
          type="checkbox"
          :id="`checkbox_${id}`"
          required
        />
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: "ProfilingCheckbox",
  data () {
    return {
      localChecked: this.checked,
      showCheckbox: false
    };
  },
  props: {
    label: {
      type: String,
      default: "Empty Link Text"
    },
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    checked: {
      required: false,
      type: Boolean,
      default: false
    },
    styles: {
      type: Object,
      required: false,
      default: () => {}
    },
    colorSchema: {
      type: String,
      default: ""
    },
    endpoint: {
      type: String,
      default: "/getProfilingId"
    }
  },
  mounted () {
    if (!this.customerToken && (!this.$route.query.page || !this.$route.query.page.includes('reupload'))) {
      this.refreshTempId().then(res => {
        // Set customer token
        this.setCustomerToken(res)
        const formData = {
          campaignId: this.campaignId,
          customerToken: res
        }
        this.sendRequest({ endpoint: this.endpoint, formData })
          .then(res => {
            this.setProfiling(res.profilingId)
            if (res.profilingId == false) {
              this.showCheckbox = true
            }
          })
      })
    }
    this.$emit("component-updated", {
      checked: !!this.checked
    });
    this.$emit("input", !!this.checked);
  },
  methods: {
    ...mapMutations(['setProfiling', 'setCustomerToken']),
    ...mapActions(['sendRequest', 'refreshTempId'])
  },
  watch: {
    localChecked (value) {
      this.$emit("component-updated", {
        checked: value
      });
      this.$emit("input", value)
      this.setProfiling(value)
    }
  },
  computed: {
    ...mapGetters(['profiling', 'customerToken', 'campaignId']),
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
}

/* The container */
.box-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  line-height: 27px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.box-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.box-container .checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 1px solid var(--color);
}

/* On mouse-over, add a grey background color */
.box-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.box-container input:checked ~ .checkmark {
  background-color: var(--color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.box-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.box-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.box-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.down-arrow {
  width: 26px;
  transition: all 0.3s;
  margin-left: 10px;
  margin-top: -8px;
}
[aria-expanded="true"] {
  .down-arrow {
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.card-body-dop {
  border: none;
  padding-left: 35px;
  background-color: transparent;
}

.expandedDiv {

  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
  -moz-transition: height 0.4s ease-out;; /* Firefox 4 */
  -webkit-transition: height 0.4s ease-out;;  /* Safari and Chrome */
  -o-transition: height 0.4s ease-out;;  /* Opera */

  &Image {
    -webkit-transform: rotate(180deg); /* Safari and Chrome */
    -moz-transform: rotate(180deg);   /* Firefox */
    -ms-transform: rotate(180deg);   /* IE 9 */
    -o-transform: rotate(180deg);   /* Opera */
    transform: rotate(180deg);
  }
}
</style>
