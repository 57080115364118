<template>
  <div class="smsVerificationWrapper col-sm-10 mb-2">
    <ValidationObserver
      ref="codeInput"
      id="codeInput"
      class="w-100"
    >
      <textInput
        name="code"
        id="code"
        v-model="code"
        label=""
        placeholder="Verification code"
        :validation="`required`"
        :label-on-top="false"
        :no-label="true"
        :styles="inputStyles"
      />
      <!-- </ValidationProvider> -->
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import textInput from "@/components/renderers/text-input.vue";

export default {
  name: "SmsReceiving",
  components: {
    textInput
  },
  props: {
    endpoint: {
      type: String,
      default: '/mobileVerification'
    },
    successAction: {
      type: Object,
      required: true
    },
    inputStyles:{
      type:Object,
      required:false,
      default:()=>{}
    },
    keepInState: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      code: ""
    };
  },
  methods: {
    ...mapActions(['lastStep', 'sendRequest']),
    async verify () {
      const isValid = await this.$refs.codeInput.validate();
      if (!isValid) {
        this.scrollTo(`[id=codeInput`);
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      }

      const formData = {
        mobile: `${this.countryCode}${this.tel}`,
        code: this.code
      };
      this.$store.dispatch("verifyCode", { formData, endpoint: this.endpoint })
        .then(r => {
          if (this.keepInState) {
            this.keepInState.forEach(element => {
              this.$store.commit("setField", { field: element, value: r[element] })
            });
          }
          return this.lastStepCall()
        })
        .catch(err => {
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: false
          });
        });
    },
    handleOnComplete (value) {
      this.code = value;
    },
    handleOnChange (value) {
      this.code = value;
    },
    async lastStepCall () {
      try {
        const formData = {
          campaignId: this.campaignId,
          customerToken: this.customerToken,
          receiptToken: this.receiptToken,
          profilingOpt: this.profiling,
          profilingOptText: this.$t('profiling-text'),
        }
        let resp1 = await this.sendRequest({ endpoint: "/sendRUBImage", formData })
        if (resp1) {
          let resp2 = await this.lastStep()
          if (resp2) {
            const fn = this.successAction.fn;
            const params = this.successAction.params;
            this[fn](params, this);
            this.$eventHub.$emit("done-event-trigger", {
              parent: this,
              status: true
            });
          }
        }
      } catch (e) {
        console.log("Last Step Error:: ", e)
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: true
        });
      }
    },
  },
  computed: {
    ...mapGetters(['countryCode', 'tel', 'campaignId', 'customerToken', 'receiptToken', 'profiling'])
  },
  mounted () {
    this.$eventHub.$on('verifySms-event', this.verify)
  },
  beforeDestroy () {
    this.$eventHub.$off('verifySms-event', this.verify)
  }
};
</script>
<style lang="scss" scoped>
.smsVerificationWrapper {
  display: flex;
  justify-content: center;
  align-content: stretch;
}

/deep/ .otp-input {
  width: 80px;
  height: 80px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  @media (max-width: 767px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 400px) {
    width: 30px;
    height: 30px;
  }
}
.error {
  border: 1px solid red !important;
}

/deep/ .IZ-select {
  justify-content: center;
  width: 100%;
  border-radius: 100px;
  
  .IZ-select__input {
    border: none;
    box-shadow: none;
    width: 100px;
    padding-left: 0;
  }

  .IZ-select__menu {
    width: 200px !important;
  }

  .IZ-select__input--selection-slot {
    & > div {
      flex: 1 1 auto;
    }
  }

  input {
    display: none;

    &.custom-input {
      display: block;
    }
  }
}

/deep/ .hide .IZ-select__menu {
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
}

</style>
