<template>
  <span></span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "EligibilityCheck",
  mounted () {
    const formData = {
      campaignId: this.campaignId,
      customerToken: this.customerToken,
      receiptToken: this.receiptToken
    }
    this.goToPage('expired-page-total', this)
    //this.sendRequest({ endpoint: '/checkEligibilityCeMb', formData }).then(res => {
    //  // TODO: Add logic
    //  console.log("EligibilityCheck", res)
    //}).catch(err => {
    //  console.log(err)
    //})
  },
  computed: {
    ...mapGetters(['customerToken', 'receiptToken', 'campaignId'])
  },
  methods: {
    ...mapActions(['sendRequest'])
  }
};
</script>
