
export default {
  isEditMode: true,
  pageProperties: {
    testing: true,
    "pages": [
      { // Home Page
        "pageName": "home",
        isPublic: true,
        pageId: 100,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "sectionId": "fu30zq",
            "fields": [
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "rToken",
                      state: "receiptToken"
                    },
                    {
                      query: "cToken",
                      state: "customerToken"
                    },
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    },
                  ]
                }
              },
              { // TODO: Add Eligibility Check
                "fieldType": "eligibility-check",
                "fieldId": "uwxo2o1",
                properties: {
                }
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "Specify barcode number",
                        classes: 'text-left',
                        styles: {
                          "font-weight": "700",
                          "font-size": "26px",
                          "line-height": "35px"
                        }
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Please scan or enter manually the barcode from your product",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Receipt uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "alignItems": "center",
                          "justifyContent": "space-around",
                          "marginBottom": "60px"
                        },
                        "classes": "receipt-bucket",
                        "fields": [
                          { // Barcode Scanner
                            "fieldType": "barcode-scanner",
                            "fieldId": "barcodeScanner",
                            "properties": {
                              "grid": {
                                "columns": 12
                              },
                              "successAction": {
                                "fn": "goToPage",
                                "params": "product-upload"
                              },
                              "endpoint": "/submitProducts",
                              "texts": {
                                receiptUpload: 'Upload',
                                receiptUploadDescription: 'Upload barcode image',
                                receiptUploadSubDescription: 'Drag & Drop',
                                wrongFormat: 'The file format is not supported. It will only .jpeg, .png and .pdf file accepted.',
                                fileSizeLimit: 'The file is too large. Only files up to 16 MB are supported.',
                                barcodeScan: 'SCAN',
                                stop: 'STOP',
                                barcodeScanDescription: 'Click here and scan the barcode.',
                                barcodeScanSubDescription: '',
                                barcodeAdd: 'APPLY',
                                barcodeAddDescription: 'Enter barcode number',
                                notParticipating: 'The barcode does not appear to belong to a P&G product. Do you think this is an error?',
                                notParticipatingVideo: 'Unfortunately, the product does not appear to be participating in the promotion. Please try to enter the barcode in the field above.',
                                timedOut: 'Unfortunately no valid barcode can be recognized. Please try to enter the barcode in the field above.',
                                cameraNotFound: 'Please turn on your camera',
                                invalidBarCode: 'The barcode number is invalid. Try it again.'
                              },
                              "cameraTimeOut": 20000,
                              "maxProductCount": 1
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Skip Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Skip",
                              classes: "btn-primary",
                              "action": {
                                "fn": "goToPage",
                                "params": "product-upload"
                              }
                            }
                          },
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Continue",
                              classes: "btn-primary",
                              "requires": ["barcodeScanner"],
                              "action": {
                                "fn": "sendBarcodeProducts",
                                "params": ""
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Product Upload
        "pageName": "product-upload",
        isPublic: true,
        pageId: 120,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "Take a photo of your product unpacked next to the piece of paper with the written unique code on it",
                        classes: 'text-left',
                        styles: {
                          "font-weight": "700",
                          "font-size": "26px",
                          "line-height": "35px"
                        }
                      }
                    },
                    { // Product Photo uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "columnGap": "20px",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          { // Product photo upload
                            "fieldType": "product-upload",
                            "fieldId": "prodPhoto",
                            "properties": {
                              texts: {
                                choose: 'UPLOAD',
                                text1: 'Drop your photo here or select file.',
                                text2: 'Only JPG and PNG files are supported Maximum file size: 10MB',
                                wrongFormat: 'This file format is not supported. Only .jpeg and .png files are accepted.',
                                fileSizeLimit: 'This file is too big. Only files up to 10MB can be uploaded.'
                              },
                              "colorSchema": "var(--color2)",
                              "grid": {
                                "columns": "6",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "endpoint": "/uploadProductPhoto",
                              "successAction": {
                                "fn": "goToPage",
                                "params": "thank-you"
                              }
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "column"
                              },
                              "fields": [
                                {
                                  "fieldType": "unique-code",
                                  "fieldId": "uwxo2ox",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                    },
                                    "endpoint": "/createUniqueCodeCeMb",
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Continue",
                              classes: "btn-primary",
                              "requires": ["prodPhoto"],
                              "action": {
                                "fn": "sendProductPhotos",
                                "params": "lastStepMoneyback"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Thank you Page
        "pageName": "thank-you",
        pageId: 113,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    { // Key visual
                      "fieldType": "key-visual",
                      "fieldId": "key100",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        styles: {
                          "padding": '0 0 0 0'
                        },
                        noContainer: true,
                        desktopImageUrl: "assets/images/banner/sk/banner_desktop.png",
                        tabletImageUrl: "assets/images/banner/sk/banner_tablet.png",
                        mobileImageUrl: "assets/images/banner/sk/banner_mobile.png"
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: "30px"
                        },
                        text: "Your receipt / invoice and your data have been successfully sent to us and are being checked at the moment.As soon as we have checked your data, you will receive either a confirmation e-mail or an e-mail asking us if your data was incomplete or your proof of purchase could not be recorded. Please keep your reference number.",
                        classes: 'text text-center'
                      }
                    },
                    { // Clipboard
                      "fieldType": "clipboard",
                      "fieldId": "ref123",
                      "properties": {
                        "grid": {
                          "columns": 6,
                          "padding-left": "0",
                          "padding-right": "0",
                          "marginBottom": "30px"
                        },
                        "title": "Reference number",
                        "disabledInput": true,
                        "variables": {
                          "referenceNumber": "referenceNumber"
                        }
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: "50px"
                        },
                        text: "The verification can take up to 72 hours. We ask for your patience.",
                        classes: 'text text-center'
                      }
                    },
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Back to campaign page",
                        "grid": {
                          "columns": 6,
                          "alignItems": "center",
                          "justifyContent": "center",
                          marginBottom: "50px"
                        },
                        "classes": "btn-primary",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "https://www.everydayme.pl/oferty"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    ],
    "header": {
      "fieldType": "navbar",
      "fieldId": "cbx34t",
      "properties": {
        "grid": {
          "columns": 12
        },
        "logo": {
          imageSource: "assets/images/png/logo-cz.png",
          url: '/',
          alt: 'Everyday Logo'
        },
        "favicon": {
          "imageSource": "assets/images/svg/pglogo.svg"
        },
        "styles": {
          "box-shadow": "rgba(0, 0, 0, .11) 0 2px 4px 0"
        },
        "meta": [
          { "name": "title", "content": "PG Central Europe" },
          {
            "name": "description",
            "content": ``
          },
          {
            "name": "keywords",
            "content": `PG Central Europe`
          }
        ]
      }
    },
    "footer": {
    },
    "url": "http://justsnap.everydayme.pl/",
    "name": "test-campaign"
  },
  componentStatus: {},
  modal: {
    type: 'error',
    message: 'Ein Fehler ist aufgetreten',
    isActive: false
  },
  campaignId: "{{campaignId}}",
  iv: "",
  token: "",
  customerToken: '',
  receiptToken: '',
  receiptImage: '',
  referenceNumber: '',
  formData: new FormData(),
  
  campaignEndDate: '2025-01-01',
  scannerActive: false,
  file: {},
  barcode: {
    code: '',
    format: ''
  },
  productCode: '',
  searchStatus: null,
  imgList: [],
  errorMessage: "",
  errorActivate: false,
  scannedProducts: [],
  frontAndBack: false,
  countryCode: '',
  tel: '',
  consumerId: '',
  lang: 'sk',
  profiling: false,
  uniqueCode: '',
}
