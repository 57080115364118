<template>
  <div class="barcode-scanner">
    <!-- Add barcode scanning options -->
    <div class="col-lg-12 barcodeWrapper">
      <div class="whiteCard">
        <div
          class="inner"
          @click="start"
          v-if="!scannerActive && !imgList.length && !stayActive"
        >
          <div class="top">
            <img
              class="search-icon"
              src="@/assets/images/svg/barcode.svg"
            />
            <p
              class="description"
              v-html="$t(texts.barcodeScanDescription)"
            ></p>
          </div>
          <buttonComponent
            @click.native="start"
            classes="btn-tertiary"
            :text="$t(texts.barcodeScan)"
            :grid="{ columns: 6 }"
          />
        </div>
        <div
          class="inner"
          v-else
        >
          <select
            v-if="videoDevices.length > 1 && scannerActive && !isIOS"
            v-model="selectedDeviceId"
            @change="switchCamera"
          >
            <option
              v-for="(device, index) in videoDevices"
              :value="device.deviceId"
              :key="device.label"
            >
              {{
                device.label.includes("front")
                  ? `Vordere Kamera ${index}`
                  : device.label.includes("back")
                    ? `Rückseitige Kamera ${index}`
                    : `Kamera ${index}`
              }}
            </option>
          </select>
          <div
            id="videoWindow"
            class="video"
            :class="{
              active: (scannerActive && cameraIsFound) || stayActive
            }"
          >
            <video
              id="video"
              src=""
            ></video>
            <div
              v-if="!scannerActive && !!imgList.length"
              class="video-icon"
            >
              <b-icon
                icon="check-circle"
                style="width: 120px; height: 120px"
                variant="success"
              ></b-icon>
            </div>
          </div>
          <buttonComponent
            v-if="scannerActive || stayActive"
            @click.native="stop"
            :text="$t(texts.stop)"
            classes="btn-tertiary"
            :grid="{ columns: 6 }"
          />
        </div>
      </div>
      <span class="or or2"></span>
      <div class="whiteCard">
        <div class="inner">
          <div class="top">
            <img
              class="search-icon"
              src="@/assets/images/svg/barcodeWrite.svg"
            />
            <p
              class="description"
              v-html="$t(texts.barcodeAddDescription)"
            ></p>
          </div>
          <search-module
            @some-event="redirect($event)"
            :endpoint="endpoint"
            :max-product-count="maxProductCount"
            :texts="texts"
          />
        </div>
      </div>
    </div>
    <div
      v-if="list.length"
      class="productListContainer container"
    >
      <productsList
        :full-size="true"
        :max-product-count="2"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { BrowserBarcodeReader } from "@zxing/library";
import { promiseTimeout } from "./promise-timeout";
import SearchModule from "./SearchModule.vue";
import ProductsList from "../ProductList.vue";
import buttonComponent from "../button-component.vue";

const codeReader = new BrowserBarcodeReader();

export default {
  name: "Scanner",
  components: {
    SearchModule,
    ProductsList,
    buttonComponent
  },
  mounted () {
    codeReader
      .listVideoInputDevices()
      .then((videoInputDevices) => {
        if (videoInputDevices[1] && videoInputDevices[1].deviceId) {
          this.videoDevices = videoInputDevices;
          this.selectedDeviceId = videoInputDevices[1].deviceId;
        } else if (videoInputDevices[0] && videoInputDevices[0].deviceId) {
          this.videoDevices = videoInputDevices;
          this.selectedDeviceId = videoInputDevices[0].deviceId;
        }
      })
      .catch((err) => console.error(err));
  },
  data() {
    return {
      selectedFile: null,
      errorTextVisible: false,
      selectedDeviceId: null,
      url: null,
      stopPressed: false,
      errorPicDisplay: false,
      cameraIsFound: true,
      videoDevices: [],
      hideCross: false,
      stayActive: false,
    };
  },
  props: {
    width: {
      type: Number,
      default: 500
    },
    height: {
      type: Number,
      default: 500
    },
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    colorSchema: {
      required: false,
      type: String,
      default: "var(--color2)"
    },
    cameraTimeOut: {
      type: Number,
      default: 20000
    },
    tabletView: {
      type: Boolean
    },
    maxProductCount: {
      type: Number,
      default: 1
    },
    endpoint: {
      type: String,
      default: "/searchBarcodeCentralEurope"
    }
  },
  computed: {
    ...mapState({
      barcode: (state) => state.barcode,
      file: (state) => state.file,
      scannerActive: (state) => state.scannerActive,
      imgList: (state) => state.imgList,
      list: (state) => state.scannedProducts,
      status: (state) => state.searchStatus
    }),
    isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    }
  },
  methods: {
    ...mapActions(['searchProductByBarcode']),
    ...mapMutations(['setErrorActivate', 'setSearchStatus', 'setBarCode', 'setScannerStatus', 'setImgList', 'setProductCode', 'setScannerError']),
    onFilePicked(file) {
      this.url = URL.createObjectURL(file);
      this.selectedFile = file;
      this.decode();
      this.errorTextVisible = this.errorPicDisplay = false;
      this.$emit("input", file);
      this.setErrorActivate(false);
    },
    decode() {
      this.errorTextVisible = this.errorPicDisplay = false;
      this.setErrorActivate(false);
      this.setSearchStatus(null);
      codeReader
        .decodeFromImage(undefined, this.url)
        .then((result) => {
          const foundResult = result.text;
          this.setBarCode(foundResult);
          // Barcode is found but need to check if it exists in this campaign
          this.searchProductByBarcode({
            barcode: this.barcode,
            maxProductCount: this.maxProductCount,
            endpoint: this.endpoint
          }).then((res) => {
            res = res.result;
            console.log(res);
            if (res.length === 0) {
              this.setScannerError(
                this.$t(this.texts.notParticipating)
              );
              this.searchProductByBarcode({
                barcode: this.barcode,
                maxProductCount: this.maxProductCount,
                endpoint: this.endpoint
              });
            }
          });
        })
        .catch((err) => {
          console.log("over here ");
          console.error(err);
          this.setBarCode("");
          this.setScannerError(this.$t(this.texts.invalidBarCode));
          this.searchProductByBarcode({
            barcode: this.barcode,
            maxProductCount: this.maxProductCount,
            endpoint: this.endpoint
          });
        });
    },
    start() {
      this.errorTextVisible = false;
      this.setErrorActivate(false);
      this.stopPressed = false;
      this.setBarCode(null);
      this.setSearchStatus(null);
      this.setScannerStatus(true); // Update scanner if no error message

      if (!this.cameraIsFound) {
        navigator.getUserMedia =
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia;

        if (typeof navigator.mediaDevices.getUserMedia !== "undefined") {
          navigator.mediaDevices
            .getUserMedia({
              video: true
            })
            .then((res) => {
              // Camera is on and can be restarted then
              this.errorPicDisplay = false;
              this.cameraIsFound = true;
              this.start();
              console.log("CAMERA ON");
            })
            .catch((err) => {
              console.log("CAMERA OFF");
              console.log(err);
            });
        }
      }

      // Run the actual code reader if scannerActive is true
      if (this.scannerActive) {
        console.log("Chacking barcode");
        let codeReaderPromise = promiseTimeout(
          this.cameraTimeOut,
          codeReader.decodeOnceFromVideoDevice(this.selectedDeviceId, "video")
        );
        codeReaderPromise
          .then((result) => {
            this.setScannerStatus(true);
            const foundResult = result.text;
            this.setBarCode(foundResult);
            if (this.barcode) {
              this.playSound();
              this.searchProductByBarcode({
                barcode: this.barcode,
                maxProductCount: this.maxProductCount,
                endpoint: this.endpoint
              });
            }
          })
          .catch((err) => {
            console.error(err);

            // Error Message When Camera not found
            if (err?.name == "NotAllowedError") {
              this.setScannerError(
                this.$t(this.texts.cameraNotFound)
              );
              console.log(this.texts.cameraNotFound);

              // When camera is not found, turn camer is found to false
              this.cameraIsFound = false;
            }

            // The promise timed out
            if (err === "time-out") {
              this.setScannerError(this.$t(this.texts.timedOut));
              this.errorPicDisplay = true;
            }

            this.searchProductByBarcode({
              barcode: this.barcode,
              maxProductCount: this.maxProductCount,
              endpoint: this.endpoint
            });
            this.errorPicDisplay = this.stopPressed === true ? false : true;
          })
          .finally(() => {
            // With a completion of a Scan or Failure, in both cases the Video will be closedss
            this.stop();
          });
      }
    },
    stop() {
      this.setScannerStatus(false);
      codeReader.reset();
      this.stopPressed = true;
    },
    playSound() {
      let sound =
        "http://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3";
      if (sound) {
        let audio = new Audio(sound);
        audio.play();
      }
    },
    unsetFile() {
      this.setImgList([]);
      this.setProductCode("");
      this.errorTextVisible = this.errorPicDisplay = false;
      this.setErrorActivate(false);
    },
    switchCamera(e) {
      this.stayActive = true;
      this.selectedDeviceId = e.target.value;
      delete this.$store.state.errorActivate;
      this.hideCross = true;
      codeReader.reset();
      setTimeout(() => {
        this.start();
        this.stayActive = false;
        this.$store.state["errorActivate"] = false;
        this.hideCross = false;
      }, 100);
    }
  }
};
</script>

<style scoped lang="scss">
.barcode-scanner {
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
}

.barcodeWrapper{
  padding: 0;
  display: grid;
  grid-template-columns: 40% 0fr 40%;
  gap: 15px;
  justify-content: space-evenly;

  @media (max-width: 1121px) {
    grid-template-columns: 1fr;
  }
  
  .whiteCard {
    min-height: 300px;
    background: #FFFFFF;
    border: 3px solid #DFDFF7;
    box-shadow: 0px 6px 16px rgba(17, 17, 17, 0.35);
    border-radius: 8px;
    padding: 20px;

    .inner {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 20px;

        .search-icon {
          width: 80px;
          height: 50px;
        }
      }
    }

    .description, .subDescription {
      text-align: center;
    }
  }
}
#videoWindow {
  height: 0;
  overflow: hidden;
  width: 100%;
  position: relative;
  margin: 0px auto;
  &.active {
    height: auto;
    width: 100%;
  }
}
video {
  width: 100%;
}
canvas {
  max-width: 100px;
  max-height: 100px;
}
.video-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.whiteBg {
  background: #ffffff 0% 0% no-repeat padding-box;
}

.productListContainer {
  margin-top: 80px;
  width: 100%;
}

@media (max-width: 1121px) {
  .fileUploaderWrapper {
    &3 {
      grid-column: 1 / 4;
    }
  }
  .or {
    &2 {
      grid-column: 1 / 4;
    }
  }
}


</style>
