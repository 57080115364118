/* eslint-disable */

<template>
  <div>
    <router-view />
  </div>
</template>
<script>
const version = process.env.VUE_APP_VERSION;

export default {
  created () {
    // Remove console logs when in production
    if (process.env.NODE_ENV !== "development" && this.$route.query.version != "test") {
      console.log = function () { };
    }
    console.log("%cV" + version, "color:red; font-size:32px;");
  },
  mounted () {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.contentBoxSize) {
          // The standard makes contentBoxSize an array...
          if (entry.contentBoxSize[0]) {
              setTimeout(() => {
                window.top.postMessage(
                  { request: 'iframeHeight', value: `${entry.target.scrollHeight}` },
                  '*'
                )
              }, 1500);
          }
        }
      }
    });
    resizeObserver.observe(document.body);
  },
  methods: {
    // TODO: Catch statement added because of node js
    oneTrustModal: function () {
      // eslint-disable-next-line no-undef
      Optanon.OnConsentChanged(() => {
        if (window.segmentaCookieHandler) window.segmentaCookieHandler();
        if (!window.nsId && !window.nsIdPending && window.nsUrl) {
          window.nsIdPending = true;
          // eslint-disable-next-line no-undef
          fetchJsonp(window.nsUrl, {
            jsonpCallbackFunction: "neustar_response"
          })
            .then(function (response) {
              return response.json();
            })
            .then(function (json) {
              window.nsId = json.nsid;
            })
            .catch(function (er) {
              console.log(er)
            });

          if (window._agknEchoTag) {
            var a = window._agknEchoTag;
            a.setBpId("firstp");
            a.addEchoKeyValue("event", "page_landing");
            a.generateTag();
          }
        }
      });
      // eslint-disable-next-line
      Optanon.ToggleInfoDisplay();
    }
  }
};
</script>
<style lang="scss">
@import "~@/assets/styles/_application.scss";
.cookie_button button {
  font-size: 14px;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #034c90;
  right: 0;
}
.otFloatingRoundedCorner{
  width: 100% !important;
}
.cookie_button button:hover {
  color: #034c90;
  background: #fff;
}
.cookie_button {
  border: none;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  text-align: left;
  position: fixed;
  right: 0;
  z-index: 2;
  bottom: 0px;
  opacity: 0.9;
  background: var(--brand-color);
  color: #000000;
  display: none;
  overflow: auto;
}
.cookie_button.open {
  display: block;
}
.btn-cookie {
  font-size: 20px;
  letter-spacing: 1px;
  color: #000;
  transition: all 0.3s;
  white-space: normal;
  line-height: 1.36;
  text-align: center;
  padding: 9px 5px 9px;
  text-transform: uppercase;
  border-radius: 0rem;
  font-family: "BebasNeue", sans-serif;
  border: none;
  line-height: 1;
  &.disabled {
    opacity: 0.5;
  }
  .btn-transparent {
    background-color: transparent;
    color: #ffffff;
    border-color: #ffffff;
  }
  .btn-transparent:hover,
  .btn-transparent:focus {
    background-color: transparent;
    color: #ffffff;
  }
  &-transparent {
    background-color: transparent;
    color: #ffffff;
    line-height: 1.2;
    .arrow_right {
      float: right;
      padding-left: 15px;
    }
  }
  &-big {
    width: 315px;
    max-width: 100%;
  }
}

</style>
