<template>
  <div :style="cssVars">
    <div
      class="wrapper input-group"
      :style="styles"
    >
      <span class="title">{{ $t(title) }} </span>
      <span
        class="form-control"
        ref="target"
        id="copied-tooltip"
      >{{ value }}</span>
      <!-- <input
        type="text"
        class="form-control"
        id="copied-tooltip"
        ref="target"
        :value="value"
        aria-label="Value"
      > -->
      <img
        src="@/assets/images/svg/clipboard.svg"
        alt="Copy"
        @click="copy"
        class="clipboard"
      >
    </div>
    <b-tooltip
      ref="tooltip"
      target="copied-tooltip"
      noninteractive
      disabled
    >
      {{ $t('Copied to clipboard') }}
    </b-tooltip>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
export default {
  name: "Clipboard",
  props: {
    text: {
      type: String,
      default: "Empty paragraph"
    },
    title: {
      type: String,
      default: "Empty paragraph"
    },
    variables: Object,
    disabledInput: {
      type: Boolean,
      default: false
    },
    colorSchema: {
      type: String,
      default: "#888888"
    },
    styles: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    value () {
      let result = this.text;
      if (this.variables) {
        for (const variable in this.variables) {
          const value = this.$store.state[variable];
          result = value;
        }
      }
      return result;
    },
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    }
  },
  methods: {
    copy () {
      var copyText = this.$refs.target;
      // copyText.innerText.select();
      // copyText.setSelectionRange(0, 99999); /* For mobile devices */
      navigator.clipboard.writeText(copyText.innerText);
      this.$refs.tooltip.$emit('open')
      setTimeout(() => {
        this.$refs.tooltip.$emit('close')
      }, 2000)
    },
    updateComponent: debounce(function (event) {
      this.$emit("component-updated", {
        text: event.target.innerText
      });
    }, 2000)
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: linear-gradient(351.63deg, #FFFFFF 10.06%, rgba(255, 255, 255, 0.176136) 84.53%, rgba(255, 255, 255, 0) 129.21%);
  border: 1px solid #5D5FEF;
  border-bottom: 3px solid #5D5FEF;
  border-radius: 12px;
  height: 60px;
  padding: 5px 20px 5px 20px;
  align-items: center;
  @media (max-width: 767px) {
    padding: 5px 10px;
    .b-icon {
      font-size: 20px !important;
    }
  }
}


.title {
  font-size: 16px;
  line-height: 26px;
  font-weight: bold;
  color: var(--color);
  @media (max-width: 767px) {
    font-size: 11px;
  }
}
input {
  font-size: 16px;
  line-height: 26px;
  font-weight: bold;
  border: none;
  background: transparent;
  padding: 5px;
  &:disabled {
    background: transparent;
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }
}

#copied-tooltip {
  font-weight: 700;
  border: none;
  padding-left: 5px;
  padding-bottom: 0;
  padding-top: 8px;
  @media (max-width: 767px) {
    font-size: 12px;
  }
}

.clipboard {
  cursor: pointer;
  
  @media (max-width: 767px) {
    max-width: 15px;
  }
}

</style>
