<template>
  <span></span>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: "IbanReupload",
  mounted () {
    const customerToken = this.$route.query.customerToken;
    if (customerToken)
      this.$store.dispatch("isUpdateIban", { customerToken, campaignId: this.campaignId })
        .then(res => {

          // If Customer Token Not Found
          if (!res.response) {
            this.onUpdateIbanError()
            return
          }

          // Everything can proceed as normal
          this.setCustomerToken(customerToken)
        })
        .catch(err => {
          this.onUpdateIbanError()
        })
    else {
      this.onUpdateIbanError()
    }
  },
  computed: {
    ...mapGetters(['campaignId'])
  },
  methods: {
    ...mapMutations(['setCustomerToken']),
    onUpdateIbanError () {
      this.goToPage('expired-page', this)
    }
  }
};
</script>
