<template>
  <div class="stepCardWrapper" :style="`--title-color: ${titleColor}; --border-color: ${borderColor}`">
    <div class="stepImage">
      <img :src="imageSource" alt="">
    </div>
    <div class="stepCardContent">
      <p class="stepTitle">{{ stepTitle + ' ' + stepNo }}</p>
      <p class="stepDescription">{{ stepDescription }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "step-card",
  props: {
    fields: {
      required: false,
      type: Array,
      default: () => []
    },
    imageSource: {
      required: true,
      type: String
    },
    stepTitle: {
      required: true,
      type: String,
      default: "Step Title"
    },
    stepNo: {
      type: String,
      default: "1"
    },
    stepDescription: {
      required: true,
      type: String,
      default: "Step Description"
    },
    titleColor: {
      type: String,
      default: "black"
    },
    borderColor: {
      type: String,
      default: "black"
    }
  }
};
</script>

<style lang="scss" scoped>
.stepCardWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0 32px;
  background: #FFFFFF;
  border: 1px solid #DED7F4;
  box-sizing: border-box;
  border-radius: 16px;
  max-width: 305px;
  height: 134px;
  margin: 28px;

  .stepImage img {
    height: 65px;
    width: 65px;
    margin-right: 16px;
  }

  .stepTitle {
    margin: 0;
    padding: 0;
    font-size: 24px;
    line-height: 30px;
    font-weight: 800;
    color: #FD7FD1;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  .stepDescription {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 20px;
    color: #221166;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

</style>
