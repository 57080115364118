<template>
  <div
    class="gray_block"
  >
    <div
      class="mainContent"
      tabindex="0"
    >
      <div
        class="inner_gray_block"
      >
        <div
          class="desc"
          v-html="$t(texts.descText)"
        ></div>
        <div
          class="subDesc"
          v-html="$t(texts.descSubText)"
        ></div>
      </div>
      <div class="row align-items-center flex-nowrap">
        <div class="col-lg-5 col-sm-6 px-0">
          <img
            class="img-fluid receipt-image"
            :src="receiptImage"
            alt="receipt example"
          >
        </div>
        <div class="col-lg-7 col-sm-6">
          <ul class="wraper_punkt">
            <li
              class="media"
              :key="step.label"
              v-for="step in steps"
            >
              <div class="item">
                {{ $t(step.label) }}
              </div>
              <div class="media-body item_text">
                {{ $t(step.text) }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        v-if="!hideExamples"
        class="wrapper_check_block"
      >
        <div class="checkMark">
          <div class="image-wrapper">
            <img
              class="img-fluid"
              src="../../assets/images/png/check_1.png"
              alt="Correct"
            >
            <img
              class="checkMark_img checkMark_img_1"
              src="../../assets/images/svg/checkMark.svg"
              alt="Correct"
            >
          </div>
          <div class="text">
            {{ $t(this.texts.correct) }}
          </div>
        </div>
        <div class="checkMark">
          <div class="image-wrapper">
            <img
              class="img-fluid"
              src="../../assets/images/png/check_2.png"
              alt="Incorrect"
            >
            <img
              class="cross_red_img cross_red_img_2"
              src="../../assets/images/svg/cross_red.svg"
              alt="Incorrect"
            >
          </div>
          <div class="text">
            {{ $t(this.texts.notReadabe) }}
          </div>
        </div>
        <div class="checkMark">
          <div class="image-wrapper">
            <img
              class="img-fluid"
              src="../../assets/images/png/check_3.png"
              alt="Incorrect"
            >
            <img
              class="cross_red_img cross_red_img_3"
              src="../../assets/images/svg/cross_red.svg"
              alt="Incorrect"
            >
          </div>
          <div class="text">
            {{ $t(this.texts.folded) }}
          </div>
        </div>
        <div class="checkMark">
          <div class="image-wrapper">
            <img
              class="img-fluid"
              src="../../assets/images/png/check_4.png"
              alt="Incorrect"
            >
            <img
              class="cross_red_img cross_red_img_4"
              src="../../assets/images/svg/cross_red.svg"
              alt="Incorrect"
            >
          </div>
          <div class="text">
            {{ $t(this.texts.cropped) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ReceiptInformation",
  props: {
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    steps: {
      required: false,
      type: [Array, Object],
      default: () => []
    },
    hideExamples: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    pullDown: false
  }),
  computed: {
    shouldDisplayFull () {
      return screen.width > 992 ? true : false
    },
    receiptImage () {
      try {
        return require(`@/${this.$t('receipt-image')}`)
      } catch {
        return require("@/assets/images/png/receipt.png")
      }
    }
  },
  methods: {
    pull () {
      this.pullDown = !this.shouldDisplayFull ? !this.pullDown : false
    }
  }
};
</script>
<style lang="scss" scoped>
.gray_block {
  background-color: transparent;
  padding: 10px 30px;
  border-radius: 4px;
  @media (max-width: 767px) {
    padding: 5px;
  }
}
.wraper_punkt {
  margin-bottom: 15px;
  li {
    margin-bottom: 15px;
    font-size: 16px;
    align-items: center;
    @media (max-width: 991px) {
      margin-bottom: 15px;
    }
    .item {
      width: 20px;
      height: 20px;
      display: inline-block;
      color: #4E4B66;
      border-radius: 100px;
      text-transform: uppercase;
      margin-right: 10px;
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
    }
    .item_text {
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.16px;
      text-align: left;
      color: var(--text-color-4);
      @media(max-width: 1024px){
        font-size: 14px;
      }
      @media (max-width: 668px) {
        font-size: 10px;
        line-height: 20px;
      }
    }
  }
}
.checkMark {
  position: relative;
  float: left;
  margin-left: 15px;
  max-width: 100px;
  .image-wrapper {
    width: 90px;
    height: 110px;
    border: 2px solid rgba(159, 174, 229, 0.3);
    border-radius: 8px;
    padding: 10px;
  }
  &:first-child {
    margin-left: 0px;
  }
  .checkMark_img {
    position: absolute;
    top: 100px;
    left: 100%;
    margin-left: -27px;
    width: 18px;
    @media (max-width: 767px) {
      max-width: 30px;
      margin-left: -15px;
    }
  }
  .cross_red_img {
    position: absolute;
    top: 100px;
    left: 100%;
    margin-left: -27px;
    width: 18px;
    @media (max-width: 767px) {
      max-width: 24px;
      margin-left: -12px;
    }
  }
  .text {
    margin-top: 10px;
    text-align: center;
    word-wrap: break-word;
    font-size: 12px;
    line-height: 16px;
  }
}
.wrapper_check_block {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  @media (max-width: 991px) {
    padding-top: 20px;
    flex-wrap: wrap;
  }
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.receipt-image {
  max-width: 180px;
  @media (max-width: 991px) {
    max-width: 150px;
  }
}

.desc {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #1D222A;
  text-align: left;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    margin: 10px 0;
    font-size: 16px;
    line-height: 20px;
  }
}
.subDesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4E4B66;
  text-align: left;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 15px;
  }
}

.drop_down_wrapper {
    padding: 10px !important;
    border-radius: 100px;
    margin-bottom: 20px !important;
}

@media all and (orientation: landscape ) {

    .drop_down {
      display: flex;
      justify-content: space-evenly;
      align-content: center;
      align-items: center;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .drop_down_wrapper {
        padding: 10px !important;
        border-radius: 100px;
        margin-bottom: 20px !important;
    }
}

.mainContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

</style>
