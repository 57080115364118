
export default {
  isEditMode: true,
  pageProperties: {
    testing: false,
    "pages": [
      { // 404
        "pageName": "error-page",
        pageId: 999,
        isPublic: true,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              { // Container
                "fieldId": "secondaryErrorBucket",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "25vh",
                    "marginBottom": "60px"
                  },
                  classes: "maxWidthOverride transparentBg errorPageMarginTop",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "oopsErrorPage",
                      "properties": {
                        "text": `Oops...`,
                        classes: "heading text-center overrideMarginBot ",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "8",
                          "marginTop": "50px",
                          "marginBottom": "40px"
                        }
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "error404text",
                      "properties": {
                        "text": `404`,
                        "classes": 'text-center paragraphBg',
                        styles: {
                          "font-size": "100px",
                          "line-height": "120px",
                          "color": "var(--color-1)",
                          "font-weight": "700"
                        },
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "8",
                          "marginTop": "0px",
                          "marginBottom": "10px"
                        }
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "errorSubHeading",
                      "properties": {
                        "text": `Oops, something went wrong. Please try again.`,
                        "classes": 'text text-center',
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "8",
                          "marginTop": "10px",
                          "marginBottom": "50px"
                        }
                      }
                    },
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Back to campaign page",
                        "grid": {
                          "columns": "6",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        classes: "btn-primary",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "#homepage"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Expired page
        "pageName": "expired-page",
        pageId: 998,
        isPublic: true,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "marginTop": "50px",
              marginBottom: "50px"
            },
            "fields": [
              {
                "fieldType": "empty-bucket",
                "fieldId": "expiredImageBucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "20px"
                  },
                  classes: "container",
                  fields: [
                    {
                      "fieldType": "square-image",
                      "fieldId": "gb0wjv",
                      "properties": {
                        "imageSource": "assets/images/svg/expired.svg",
                        alternateText: "Expired",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          "marginTop": "0px",
                          "marginBottom": "30px"
                        },
                        "classes": "expiredPageImage"
                      }
                    }
                  ]
                }
              },
              { // Container
                "fieldId": "secondaryErrorBucket",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "0vh",
                    "marginBottom": "0px"
                  },
                  classes: "container",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "headerExpiredPage",
                      "properties": {
                        "text": `This session has expired`,
                        "classes": 'heading text-center',
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "8",
                          "marginTop": "20px",
                          "marginBottom": "40px"
                        }
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "subHeaderExpiredPage",
                      "properties": {
                        "text": `Unfortunately the link is no longer valid! Please participate again or contact our support team`,
                        "classes": "text text-center",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "9",
                          marginBottom: "50px"
                        }
                      }
                    },
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Back to campaign page",
                        "grid": {
                          "columns": "6",
                          "alignItems": "center",
                          "justifyContent": "center",
                          marginBottom: "50px"
                        },
                        "classes": "btn-primary",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "#homepage"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Campaign Is Over
        "pageName": "campaign-is-over",
        pageId: 997,
        isPublic: true,
        "sections": [
          { // Heading
            "sectionId": "vw3456h756",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px",
                    "marginTop": "80px"
                  },
                  fields: [
                    { // Key visual
                      "fieldType": "key-visual",
                      "fieldId": "key100",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        styles: {
                          "padding": '0 0 0 0',
                          "max-width": '75vw'
                        },
                        noContainer: true,
                        desktopImageUrl: "assets/images/banner/{{campaignPath}}/banner_desktop.png",
                        tabletImageUrl: "assets/images/banner/{{campaignPath}}/banner_tablet.png",
                        mobileImageUrl: "assets/images/banner/{{campaignPath}}/banner_mobile.png"
                      }
                    },
                    { // Container
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginBottom": "60px"
                        },
                        classes: "container",
                        fields: [
                          { // Heading
                            "fieldType": "paragraph",
                            "fieldId": "q432vg65",
                            "properties": {
                              "grid": {
                                "columns": "9",
                                "alignItems": "center",
                                "justifyContent": "center",
                                marginBottom: "30px"
                              },
                              "classes": 'heading text-center',
                              "text": `The promotion period is over`,
                              "styles": {
                                "text-transform": "uppercase"
                              }
                            }
                          },
                          { // Sub Heading
                            "fieldType": "paragraph",
                            "fieldId": "sh34f423",
                            "properties": {
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": 10,
                                marginBottom: "50px"
                              },
                              "classes": 'text text-center',
                              "text": `Sign up to stay up to date. Receive special offers and updates.`
                            }
                          },
                          {
                            "fieldType": "button-component",
                            "fieldId": "9e9joo",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "classes": "btn-primary",
                              "text": "Back to campaign page",
                              "disabled": false,
                              "action": {
                                "anchor": true,
                                "link": "#homepage"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Expired page total
        "pageName": "expired-page-total",
        pageId: 9997,
        isPublic: true,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "marginTop": "50px",
              marginBottom: "50px"
            },
            "fields": [
              {
                "fieldType": "empty-bucket",
                "fieldId": "expiredImageBucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "20px"
                  },
                  classes: "container",
                  fields: [
                    {
                      "fieldType": "square-image",
                      "fieldId": "gb0wjv",
                      "properties": {
                        "imageSource": "assets/images/svg/expired.svg",
                        alternateText: "Expired",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          "marginTop": "0px",
                          "marginBottom": "30px"
                        },
                        "classes": "expiredPageImage"
                      }
                    }
                  ]
                }
              },
              { // Container
                "fieldId": "secondaryErrorBucket",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "0vh",
                    "marginBottom": "0px"
                  },
                  classes: "container",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "headerExpiredPage",
                      "properties": {
                        "text": `Expired Total`,
                        "classes": 'heading text-center',
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "8",
                          "marginTop": "20px",
                          "marginBottom": "40px"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
    ],
    "header": {
    },
    "footer": {
    },
  },
  componentStatus: {},
  modal: {
    type: 'error',
    message: 'An error occured.',
    isActive: false
  },
  customerToken: '',
  receiptToken: '',
  campaignId: '',
  formData: new FormData()
}
