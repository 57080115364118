import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import i18n from './translate'
import * as rules from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en.json';
import pl from 'vee-validate/dist/locale/pl.json';
import cs from 'vee-validate/dist/locale/cs.json';
import sk from 'vee-validate/dist/locale/sk.json';

const ibantools = require('ibantools');

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('mandatory', {
  ...rules['required']
})
extend('postal', {
  params: ['land'],
  validate (value, { land }) {
    const regex = land == "Germany" ? /^(\d{5})?$/ : /^(\d{4})?$/;
    return (regex.test(value));
  }
});

extend('confirmPass', {
  params: ['pass'],
  validate (value, { pass }) {
    return value === pass
  }
})
extend('hausnr', {
  ...rules['integer']
})
extend('email', {
  ...rules['email']
})
extend('password', {
  validate: value => /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d\W]{8,}$/.test(value)
})
extend('iban', {
  validate: (value, params) => {
    const country = params[0]
    value = country + value
    // const countryCode = value.substring(0, 2)
    return ibantools.isValidIBAN(value.replace(/[^\dA-Z]/g, ''))
  }
});
extend('bic', {
  validate: value => !!ibantools.isValidBIC(value)
});
extend('sepa', {
  validate: (value) => {
    const countryCode = value.substring(2, 0)
    const countToIgnore = ['GB', "LI"]
    return ibantools.isSEPACountry(countryCode) && !countToIgnore.includes(countryCode)
  }
});
extend('namesWithHyphensAndSpaces', {
  validate: value => {
    return /^([a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]{1,}(\s|-))*[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]{1,}$/.test(value);
  }
});

extend('ibanName', {
  validate: value => {
    return /^(([a-zA-Z]{1,}(\s|-))*[a-zA-Z]{1,}){3,}$/.test(value);
  }
});

// extend('ibanNamePoland', {
//   validate: value => {
//     return /^(([a-zA-Z]{1,}(\s|-))*[a-zA-Z]{1,}){3,}$/.test(value);
//   }
// });

extend('phoneNumber', {
  ...rules['integer']
})
Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule] // copies rule configuration
    // message: wihtCustomMessages[rule] // assign message
  });
});


localize({
  en: {
    messages: en.messages
  },
  pl: {
    messages: pl.messages
  },
  cs: {
    messages: cs.messages
  },
  sk: {
    messages: sk.messages
  },
});

let LOCALE = i18n.locale;
localize(LOCALE);

// A simple get/set interface to manage our locale in components.
// This is not reactive, so don't create any computed properties/watchers off it.
Object.defineProperty(Vue.prototype, "locale", {
  get () {
    return LOCALE
  },
  set (val) {
    LOCALE = val
    localize(val);
  }
});
