<template>
  <span></span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Doi",
  props: {
    endpoint: {
      type: String,
      required: true,
      default: "/doi"
    }
  },
  data: () => {
    return {
      verification_code: "",
      loading: true,
      sent: false
    };
  },
  computed: {
    ...mapGetters(["campaignId"])
  },
  methods: {
    ...mapActions(['sendRequest'])
  },
  mounted () {
    this.verification_code = this.$route.query.verification_code;

    if (this.verification_code) {
      const formData = {
        campaignId: this.campaignId,
        verification_code: this.verification_code
      }
      this.sendRequest({ endpoint: this.endpoint, formData })
      .then(res => {
        if (res.response) {
          this.sent = true;
        }
      }).catch(err => {
        console.log(err);
        this.goToPage('error-page', this)
      });
    } else {
      this.goToPage('error-page', this)
    }
  }
};
</script>
