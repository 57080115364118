<template>
  <span></span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: "CheckReceiptToken",
  mounted () {
    const value = this.$route.query['token']
    if (this.frontAndBack) {
      return
    }
    this.goToPage('expired-page-total', this)
    //this.check72Hours(value)
    //  .then(res => {
    //    if (!res.response) {
    //      this.goToPage('expired-page', this)
    //    }
    //  })
    // Need to set frontAndBack to true, next time it will not do  72hrs check due to both front and back reupload
    if (this.$route.fullPath.includes("fe-be-produktfoto-hochladen")) {
      this.$store.commit('setFrontAndBack', true);
    }
  },
  methods: {
    ...mapActions(['check72Hours'])
  },
  computed: {
    ...mapGetters(['frontAndBack'])
  }
}
</script>
